
@media (min-width: 992px){
   .navbar-light{
        padding: 0.5rem 0rem;
    }
}
@media (min-width:1200) {
   
  
}
@media (min-width:1400) {
   
  
}