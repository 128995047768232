// fotter
.footer-section {
    width: 100%;
    position: relative;
    padding: 2rem 0rem;
    background: url("/assets/images/footer_bg.png");
    background-size: 100% 100%;
    color: $text-white;
    font-size: 0.9rem;
    font-family: "Poppins Light";
    .f-logo {
      align-items: center;
      font-family: Poppins Medium;
      font-size: 1.85rem;
      line-height: 1;
      padding: 0 0.25rem;
      width: 7rem;
      color: $text-white;
      img{
        width: 32px;
        height: 32px;
      }
    }
    h5 {
      margin-bottom: 1.25rem;
      color: $text-white;
    }
    .list-unstyled {
      li {
        padding: 0.25rem 0rem;
        a {
          color: $text-white;
          @include transition(all 0.7s);
          &:hover {
            @include transition(all 0.7s);
            color: $text-gray;
          }
        }
      }
    }
    .social-network {
      margin-top: 1rem;
      display: flex;
      li {
        a {
          width: 2.125rem;
          height: 2.125rem;
          line-height: 2.125rem;
          font-size: 1rem;
          float: left;
          text-align: center;
          color: $text-magenta;
          background-color: #d3d3d3;
          margin-right: 0.75rem;
          @include border-radius(50%);
          i {
            @include transition(all 0.7s);
          }
          &:hover {
            i {
              color: $text-white;
              @include transform(rotate(360deg));
              @include transition(all 0.7s);
            }
          }
          &.icoFacebook {
            &:hover {
              background-color: #3b5998;
            }
          }
          &.icoTwitter {
            &:hover {
              background-color: #000;
            }
          }
          &.icoGoogle {
            &:hover {
              background-color: #bd3518;
            }
          }
          &.icoYoutube {
            &:hover {
              color: #fff;
              background-color: #bd3518;
            }
          }
          
          &.icoLinkedin {
            &:hover {
              background-color: #007bb7;
            }
          }
        }
      }
    }
    .apps-link {
      display: flex;
      margin: 1.25rem 0rem;
      .play-store {
        background-color: $text-dark-black;
        padding: 0.5rem 0.75rem;
        @include border-radius(0.35rem);
        .flex-shrink-0 {
          padding-right: 0.5rem;
          img {
            width: 1rem;
          }
        }
        .flex-grow-1 {
          h5 {
            font-size: 0.45rem;
            color: $text-white;
            margin: 0rem;
          }
          p {
            font-size: 0.45rem;
            color: $text-white;
            margin: 0rem;
            margin-bottom: 0.125rem;
          }
        }
      }
    }
    .bottomTop {
      position: fixed;
      z-index: 60;
      top: auto;
      bottom: 2rem;
      right: 2rem;
      font-size: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.625rem;
      background: $text-warning-dark;
      color: $text-white;
      text-align: center;
      cursor: pointer;
      @include border-radius(50%);
      @include box-shadow(0px 1px 10px rgba(150, 0, 107, 0.1490196078));
    }
  }

  .blog-social-network {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    li{
      a {
          display: flex ;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 2.125rem;
          height: 2.125rem;
          font-size: 1rem;
          margin-right: 1rem;
          border-radius: 50%;
          // &:hover{
          //   color: #6c6cccf0;
          // }
          &.icoFacebook {
              color: #fff;
              background-color: #1877F2;
          }
          &.icoTwitter {
            font-size: 2.5rem;
            color: #000;
            height: auto;
            background-color:#fff;
          }
          &.icoLinkedin {
            color: #fff;
            background-color:#007bb7;
          }
          &.icoYoutube {
            color: #ffffff;
            background-color: #bd3518;
          }
          // &.icoGoogle {
          //   &:hover{
          //   background-color:#BD3518;
          //   } 
          // }
      }
    }

  }

  @media (min-width: 992px) {
    .footer-section {
        z-index: 1;
      }
  }
  @media (min-width: 1200px) {}
  @media (min-width: 1400px) {}
  @media (min-width: 1900px) {}