html {
  position: relative;
  min-height: 100%;
}
body {
  // letter-spacing: 0.5px;
  // padding-top: 4rem;
  font-family: "Poppins Regular" !important;
  font-weight: normal;
  color: $text-black;
  font-size: 0.95rem;
}
.fn-1 {
  font-size: 0.75rem !important;
}
.fn-2 {
  font-size: 1rem !important;
}
.fn-3 {
  font-size: 1.125rem !important;
}
.fn-4 {
  font-size: 1.25rem !important;
}
.fn-5 {
  font-size: 1.5rem !important;
}
.fn-6 {
  font-size: 1.625rem !important;
}
.fn-7 {
  font-size: 1.75rem !important;
}
.fn-8 {
  font-size: 2.25rem !important;
}
.fn-9 {
  font-size: 2.5rem !important;
}

.fn-l {
  font-family: "Poppins Light" !important;
}
.fn-r {
  font-family: "Poppins Regular" !important;
}
.fn-m {
  font-family: "Poppins Medium" !important;
}
.fn-sb {
  font-family: "Poppins SemiBold" !important;
}
.fn-b {
  font-family: "Poppins Bold" !important;
}
.ff-r{
  font-family: 'Richardson', sans-serif !important;
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
  font-family: "Poppins Medium";
  color: $text-black;
  margin-bottom: 1.25rem;
  letter-spacing: normal;
  line-height: 1.4555;
}
h1 {
  color: $text-black;
  font-size: 2.5rem;
}
h2 {
  color: $text-black;
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.125rem;
}
h6 {
  font-size: 0.9rem;
}
a {
  outline: none;
}
.text-black {
  color: $text-black !important;
}
.text-white {
  color: $text-white !important;
}
.text-warning {
  color: $text-warning !important;
}
.text-warning-dark {
  color: $text-warning-dark !important;
}
.bg-warning {
  background-color: $text-warning-dark !important;
}
.text-alice-blue{
  color:$text-alice-blue
}
.text-primary {
  color: $text-primary !important;
}
.text-primary-light {
  color: $text-primary-light !important;
}
.text-blue-magenta-light {
  color: $text-blue-magenta-light !important;
}
.text-blue-magenta {
  color: $text-blue-magenta-light-dark;
}
.text-magenta {
  color: $text-magenta !important;
}
.text-danger {
  color: $text-danger !important;
}
.text-danger-dark {
  color: $text-danger-dark !important;
}
.text-pink {
  color: $text-pink !important;
}
.text-deepsky-light {
  color: $text-deepsky-light !important;
}
.text-dark-gray {
  color: $text-dark-gray !important;
}
.bg-gray {
  background-color: $bg-gray !important;
}
.bg-white {
  background-color: $bg-white !important;
}
.bg-magenta-light {
  background-color: $text-magenta-light !important;
}
.bg-violet-light {
  background-color: $text-violet-light !important;
}
.bg-deepsky-dark {
  background-color: $text-deepsky-dark !important;
}
.bg-deepsky-extralight {
  background-color: $text-deepsky-extralight !important;
}
.bg-deepsky-light {
  background-color: $text-deepsky-light !important;
}
.bg-info-light {
  background-color: $text-primary-light !important;
}
.bg-info-extralight {
  background-color: $text-primary-extralight !important;
}

.bg-lateblue-light {
  background-color: $text-slateblue-light !important;
}
.bg-blue-magenta-light {
  background-color: $text-blue-magenta-light !important;
}
.bg-pink-light {
  background-color: $text-pink-light !important;
}
a {
  outline: none;
  text-decoration: none;
  color: $text-primary;
}
.bl-3{
    border-left: 3px solid #96006B;
    padding-left: 0.75rem;
    &.bl-warning{
      border-left-color: $text-warning;
    }
}

.maxwidth-600 {
  max-width: 600px;
}
.img-fluid-95 {
  max-width: 100%;
  height: auto;
}
.radius-1 {
  @include border-radius(1rem);
}
.radius-2 {
  @include border-radius(2rem);
}
.radius-3 {
  @include border-radius(3rem);
}
.img-mb {
  margin-bottom: 1.25rem;
}
.bottom-border {
  position: relative;
  margin-bottom: 2rem;
  padding-bottom: 0.25rem;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 25%;
    height: 2px;
    display: inherit;
    background-color: $text-black;
  }
  &.border-center {
    &:after {
      width: 5rem;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  &.border-width {
    &:after {
      width: 6rem;
    }
  }
  &.border-magenta {
    &:after {
      background-color: $text-magenta;
    }
  }
  &.border-warning {
    &:after {
      background-color: $text-warning-dark;
    }
  }
  &.border-info-light {
    &:after {
      background-color: $text-primary-dark;
    }
  }
  &.border-slateblue-light {
    &:after {
      background-color: $text-slateblue-light-dark;
    }
  }
}
.border-bottoms {
  position: relative;
  margin-bottom: 2rem;
  padding-bottom: 0.25rem;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 6rem;
    height: 2px;
    display: inherit;
    background-color: $text-magenta;
  }
  &.border-center {
    &:after {
      width: 5rem;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  &.border-width {
    &:after {
      width: 6rem;
    }
  }
  &.border-magenta {
    &:after {
      background-color: $text-magenta;
    }
  }
}
.wrapper-area {
  width: 100%;
  height: auto;
  padding: 3rem 0rem;
  background: $bg-white;
}
.ul-icon {
  padding-left: 0rem;
  li {
    position: relative;
    list-style: none;
    // padding: 0.625rem 2.5rem;
    padding: 0.625rem .5rem 0.625rem 2.5rem;
    &:before {
      position: absolute;
      left: 0;
      content: "";
      width: 1.5rem;
      height: 1.5rem;
    }
    &.fn-2 {
      font-size: 1.25rem !important;
      &::before {
        top: 0.75rem;
      }
    }
    &.fn-3 {
      font-size: 1.5rem !important;
      &::before {
        top: 0.95rem;
      }
    }
    &.tick-icon-magenta {
      &:before {
        background: url("/assets/images/tick.svg") no-repeat;
        background-size: 100%;
      }
    }
    &.tick-icon-pink {
      &:before {
        background: url("/assets/images/tick-pink.svg") no-repeat;
        background-size: 100%;
      }
    }
    &.tick-icon-info {
      &:before {
        opacity: 0.9;
        background: url("/assets/images/tick-info.svg") no-repeat;
        background-size: 100%;
      }
    }
    &.tick-icon-slateblue {
      &:before {
        opacity: 0.9;
        background: url("/assets/images/tick-slateblue.svg") no-repeat;
        background-size: 100%;
      }
    }
    &.tick-icon-white {
      &:before {
        opacity: 0.9;
        background: url("/assets/images/tick-white.svg") no-repeat;
        background-size: 100%;
      }
    }
  }
}
.heading-title {
  .under-line {
    font-family: "Poppins SemiBold";
    display: inline-block;
    position: relative;
    margin: 0rem 0 2rem;
    padding-bottom: 0.25rem;
    font-size: 1.75rem;
    z-index: 1;
    &::before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      margin: 0 auto;
      width: 90px;
      background-color: $text-magenta !important;
    }
    &::after {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      margin: 0 auto;
      width: 30px;
      background-color: $text-warning-dark !important;
    }
  }
  &.text-left {
    .under-line {
      &::before {
        margin: 0;
      }
      &::after {
        margin: 0;
      }
    }
  }
}
.qkrModule {
  .qkrimg {
    width: 2.5rem;
    height: 2.5rem;
    background-size: 2rem auto !important;
    margin-bottom: 0.25rem;
    display: inline-block;
    @include transform(scale(1));
    @include transition(all 0.7s);
  }
  .qkrsales {
    background: url(/assets/images/appicon/Qkrsales.svg) no-repeat;
    background-size: 1.688rem auto !important;
  }
  .qkrexpense {
    background: url(/assets/images/appicon/Qkrexpense.svg) no-repeat;
    background-size: 1.8rem auto !important;
  }
  .qkrprojects {
    background: url(/assets/images/appicon/Qkrprojects.svg) no-repeat;
    background-size: 1.688rem auto !important;
  }
  .qkrdesk {
    background: url(/assets/images/appicon/Qkrdesk.svg) no-repeat;
    background-size: 1.563rem auto !important;
  }
  .qkrhr {
    background: url(/assets/images/appicon/QkrHR.svg) no-repeat;
    background-size: 1.95rem auto !important;
  }
  .qkrtenders {
    background: url(/assets/images/appicon/Qkrtenders.svg) no-repeat;
  }
  .qkrvisit {
    background: url(/assets/images/appicon/Qkrvisit.svg) no-repeat;
    background-size: 1.563rem auto !important;
  }
  .qkrtask {
    background: url(/assets/images/appicon/Qkrtask.svg) no-repeat;
  }
  .qkrinventory {
    background: url(/assets/images/appicon/Qkrinventory.svg) no-repeat;
  }
}
.hero-top-banner {
  padding-top: 3.75rem;
  background-color: $bg-white;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: url('/assets/images/hero-top-banner.png') no-repeat;
    background-size: 100%;
  }
  .banner-heading {
    margin-top: 2.5rem; 
    h1 {
      margin: 0;
      margin-bottom: 1.5rem;
      font-family: "Poppins SemiBold";
      font-size: 2.125rem;
      line-height: 1.354;
    }
    p {
      font-family: "Poppins Medium";
      font-size: 1rem;
      margin: 1.125rem 0;
    }
    .btn {
      margin-top: 1rem;

      &.btn-outline-magenta {
        margin-right: 1rem;
      }
    }
    .bottom-img {
      padding-top: 1rem;
      display: flex;

      .img-fluid {
        max-width: 100%;
      }
    }
  }
  .apps {
    margin-top: 1.75rem;

    .card {
      @include box-shadow(0px 1px 10px rgba(150, 0, 107, 0.15));
      border: none;
      @include border-radius(1rem);

      .card-body {
        .apps-heading {
          margin-bottom: 2rem;
        }

        .d-flex {
          cursor: pointer;
          margin-top: 1rem;

          .flex-shrink-0 {
            padding-right: 1.25rem;
            padding-left: 0.5rem;
          }

          .flex-grow-1 {
            h5 {
              font-family: "Poppins Medium";
              margin-bottom: 0.25rem;
              font-size: 1rem;
            }

            p {
              margin: 0;
              font-size: 0.8rem;
              color: $text-black;
            }
          }

          .right-arrow {
            position: relative;

            &::after {
              content: "\f105";
              font-family: "Font Awesome 6 Free";
              color: $text-black;
              font-size: 1rem;
              font-weight: 600;
              position: absolute;
              right: 0;
              top: calc(50% - 10px);
              width: 1.5rem;
              height: 1.5rem;
              @include transform(scale(1.1));
              @include transition(all 0.3s ease);
            }
          }

          &:hover {
            .qkrimg {
              width: 40px;
              height: 40px;
              background-size: 30px auto !important;
              display: inline-block;
              @include transform(scale(1.1));
              @include transition(all 0.7s ease);
            }

            .right-arrow {
              &::after {
                color: $text-magenta;
                @include transform(translateX(7px));
                @include transition(all 0.7s ease);
              }
            }
          }
        }
      }

      .card-footer {
        cursor: pointer;
        background-color: unset;
        padding: 1rem 0;
        margin: 0 1.5rem;
        border-top: 2px solid #ddd;

        .allproducts {
          display: flex;
          width: 100%;
          text-align: left;
          border: none;
          background-color: transparent;
          @include transition(all 0.7s ease);
        }

        .right-arrow {
          position: relative;
          width: 100%;
          text-align: left;

          &::after {
            font-family: "Font Awesome 6 Free";
            content: "\f105";
            color: $text-black;
            font-size: 1rem;
            font-weight: 600;
            position: absolute;
            right: 0;
            top: calc(50% - 15px);
            width: 1rem;
            height: 1.5rem;
            @include transform (scale(1.1));
            @include transition(all 0.7s ease);
          }
        }

        &:hover {
          .right-arrow {
            &::after {
              color: $text-magenta;
              @include transform(translateX(7px));
              @include transition(all 0.7s ease);
            }
          }
        }
      }
    }
  }  
}
.apps-section {
  padding: 3rem 0;
  .container {
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: -0.75rem;
      right: 0;
      width: 180px;
      height: 180px;
      background: url('/assets/images/sidedesign.svg') no-repeat;
    }
  }
  
  .card {
    @extend .bg-gray;
    border: none;
    margin-bottom: 1.25rem;
    @include border-radius(2rem);
    @include transition(all 0.7s);
    .card-body {
      padding: 1.75rem;
      padding-bottom: 0.5rem;
      h4 {
        font-family: "Poppins Medium";
      }
      p {
        margin-bottom: 0;
      }
    } 
    .card-footer {
      padding: 0.5rem 1.75rem 1.75rem;
      border-top: none;
      background-color: transparent;
      .arrowLink {
        position: relative;
        padding-right: 1.5rem;
        font-size: 0.95rem;
        @include transition(all 0.7s);
        &::after {
          content: "\f105";
          position: relative;
          left: 0.75rem;
          font-family: "Font Awesome 6 Free";
          font-size: 0.85rem;
          font-weight: 600;
          color: $text-magenta;
          @include transition(all 0.7s);
          
        }
      }
    }
    &:hover {
      @extend .bg-white;
      @include box-shadow(0px 12px 20px rgba(0, 0, 0, 0.07));
      .qkrimg {
        width: 2.5rem;
        height: 2.5rem;
        background-size: 1.85rem auto !important;
        display: inline-block;
        // @include transform(scale(1.1));
        @include transition(all 0.7s);
      }
      .card-footer {
        .arrowLink {
          font-size: 1rem;
          &::after{
            font-weight: 700;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
.arrowLinks {
  position: relative;
  padding-right: 1.5rem;
  text-decoration: underline;
  &::after {
    content: "\f105";
    // position: absolute;
    // right: 0;
    // top: 0.125rem;
    position: relative;
    left: 0.75rem;
    top: 0rem;
    font-family: "Font Awesome 6 Free";
    font-size: 0.85rem;
    font-weight: 600;
    color: $text-magenta;
    @include transition(all 0.7s);
    
  }
}
.employee {
  width: 100%;
  height: auto;
  padding: 2rem 0rem;
  background-color: $bg-white;
  .border-right {
    border-right: 1px solid #dee2e6 !important;
  }
  .counter {
    position: relative;
    z-index: 1;
    .counter-content {
      text-align: center;
      @include transition(all 0.3s ease 0s);
      .counter-value {
        font-family: "Poppins SemiBold";
        color: $text-magenta;
        font-size: 2rem;
        font-weight: 800;
      }
      .counter-icon {
        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: 0.5rem;
        color: $text-magenta;
        display: none;
        @include transition(all 0.3s);
        &:hover {
          @include transform(rotateY(180deg));
        }
      }
      i {
        color: $text-magenta;
        font-size: 1.125rem;
      }
      .text {
        font-size: 1.5rem;
        margin: 0 0 0px 0;
        margin-left: 0.5rem;
        font-weight: 700;
      }
    }
  }
}
.platformfeatures_home {
  padding: 3rem 0rem;
  background-color: $bg-white;
  .card {
    font-family: "Poppins Medium";
    background-color: $bg-gray;
    margin-bottom: 1.5rem;
    text-align: center;
    min-height: 17.813rem;
    border: unset;
    @include border-radius(1rem);
    box-shadow: unset;
    @include transition(all 0.7s);
    .card-body {
      .platfrom-img {
        width: 4.5rem;
        height: 4.5rem;
        margin: 1.75rem auto;
        img {
          width: 4.5rem;
          height: 4.5rem;
        }
      }
      h4 {
        font-family: "Poppins SemiBold";
        font-size: 1.125rem;
        color: $text-dark-black;
        margin-top: 0.75rem;
        margin-bottom: 0rem;
        margin: 0 auto;
        padding: 0rem 3rem;
      }
      p {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        opacity: 0.8;
      }
    }
    .card-footer {
      padding-bottom: 2rem;
      background-color: transparent;
      border-top: unset;
      .link {
        position: relative;
        padding-right: 1rem;
        color: $text-magenta;
        font-size: 1rem;
        @include transition(all 0.7s);
        &:after {
          content: "\f105";
          position: relative;
          left: 10px;
          top: 0rem;
          font-size: 0.85rem;
          font-family: "Font Awesome 6 Free";
          color: $text-magenta;
          font-weight: 600;
          @include transition(all 0.7s);
        }
      }
    }
    &:hover {
      background-color: $bg-white;
      @include box-shadow(0px 2px 44px rgba(110, 110, 110, 0.26));
      .card-body {
        img {
          opacity: 1;
        }
        p {
          opacity: 1;
        }
      }
      .card-footer {
        .link {
          font-size: 1.075rem;
          &:before {
            font-size: 1.05rem;
            top: 0rem;
          }
        }
      }
    }
  }
}
.infrastructure {
  padding: 3.5rem 0rem;
  background-color: $bg-gray;
  .left-img {
    img {
      max-width: 100%;
    }
  }
  .d-flex {
    margin-top: 0.625rem;
    .icons {
      padding-top: 0rem;
      width: 1.125rem;
      margin-right: 1rem;
    }
  }
}
.accountable {
  padding: 3rem 0rem;
  background-color: $bg-white;
  .d-flex {
    .secure_icon {
      @include box-shadow(0 2px 12px 0 $text-magenta-light);
    }
  }
}
.content-box{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 1rem);
  padding-bottom: 2rem;
  padding-top: 7rem;
  background-color: #fff;
  .header-title {
    font-family: "Poppins SemiBold";
    font-size: 1.75rem;
    line-height: 1.354;
    margin-bottom: 1.75rem;
    position: relative;
    z-index: 1;
  }
  .header-text {
    font-size: 1.125rem;
  }
  .header-desc {
    font-size: 1.125rem;
  }
  .list-unstyled {
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    text-align: left;
    padding-left: 0rem;
    li {
      position: relative;
      padding-left: 1.25rem;
      padding-top: 0.25rem;
      padding-bottom: 0.5rem;
      font-size: 1rem;
      &:before {
        content: "\f105";
        font-family: "Font Awesome 6 Free";
        font-size: 0.85rem;
        font-weight: 600;
        color: $text-magenta;
        position: absolute;
        left: 0rem;
      }
    }
  }
  .btn {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
}
.content-top{
  display: flex;
  justify-content: center;
  align-items: center;
  // min-height: calc(100vh - 13rem);
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  background-color: $bg-white;
  .breadcrumb{
    margin-top: 1.5rem;
    .breadcrumb-item {
      a{
         color: $text-dark-gray;
         &:hover{
          color: $text-magenta;
         }
      }
      & + .breadcrumb-item::before {
        content: var(--bs-breadcrumb-divider, ">");
      }
      &.active{
        color: $text-dark-gray;
      }
    }
  }
  .title{
    font-size: 1.25rem;
    color: $text-magenta;
    margin-bottom: 0.25rem;
  }
  .header-title {
    font-family: "Poppins SemiBold";
    font-size: 1.75rem;
    line-height: 1.354;
    margin-top: 0rem;
    margin-bottom: 1.5rem;
  }
  .header-text {
    font-size: 1.125rem;
  }
  .header-desc {
    font-size: 1.125rem;
  }
  .list-unstyled {
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    text-align: left;
    padding-left: 0rem;
    li {
      position: relative;
      padding-left: 1.25rem;
      padding-top: 0.25rem;
      padding-bottom: 0.5rem;
      font-size: 1rem;
      &:before {
        content: "\f105";
        font-family: "Font Awesome 6 Free";
        font-size: 0.85rem;
        font-weight: 600;
        color: $text-magenta;
        position: absolute;
        left: 0rem;
      }
    }
  }
  .btn {
    margin-top: 1.5rem;
    margin-bottom: 1.75rem;
  }
}
.window-top-banner {
  display: flex;
  padding-top: 6rem;
  padding-bottom: 2rem;
  .header-title {
    font-family: "Poppins SemiBold";
    font-size: 1.75rem;
    line-height: 1.354;
    margin-bottom: 1.75rem;
  }
  .header-text {
    font-size: 1.125rem;
  }
  .header-desc {
    font-size: 1.125rem;
  }
  .btn {
    margin-bottom: 1rem;
    margin-top: 3.5rem;
  }
}
.wrapper-bg-img {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important;
  background: url(/assets/images/SpreadsheetBanner.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 480px;
  position: relative;
  z-index: 1;
  h2 {
    position: relative;
    z-index: 3;
    font-family: "Poppins SemiBold";
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
    background: $text-magenta-dark;
    @include transform(skew(220deg) translate(-25%));
    @include box-shadow(0 0 20px rgba(0, 0, 0, 0.7));
    @include transition(all 0.6s ease);
    opacity: 0.3;
    z-index: -1;
  }
}
.box {
  position: relative;
  z-index: 1;
  .card {
    background-color: $bg-white !important;
    margin-bottom: 1.5rem;
    border: 1px solid $bg-white;
    @include border-radius(1rem);
    @include box-shadow(0px 0px 18px #0000000d);
    @include transition(all 0.7s);
    .card-body {
      color: $text-dark-gray;
      h5 {
        color: $text-black;
        font-size: 1.125rem;
      }
      .text-magenta {
        color: $text-magenta !important;
      }
      img {
        width: 2.813rem;
        height: 2.813rem;
        margin-top: 0.75rem;
        margin-bottom: 1.5rem;
      }
    }
    &:hover {
      border: 1px solid $bg-white;
    }
  }
}
.all_features {
  background-color: $bg-white;
  .card {
    margin-bottom: 1.5rem;
    background-color: $bg-white;
    border: 1px solid $bg-gray;
    @include border-radius(1rem);
    @include transition(all 0.7s);
    .card-header {
      position: relative;
      overflow: hidden;
      padding: 0rem;
      margin: 1rem 1rem 0rem 1rem;
      background-color: $bg-white;
      border-bottom: 0 solid $text-white;
      @include border-top-left-radius(1rem);
      @include border-top-right-radius(1rem);
      img {
        font-family: "Poppins Regular" !important;
        font-weight: normal;
        max-width: 100%;
        @include border-radius(0.25rem);
        @include transition(all 2s cubic-bezier(0.19, 1, 0.22, 1) 0ms);
      }
    }
    .card-body {
      background-color: $bg-white;
      padding: 1rem;
      margin: 0rem 1rem 1rem 1rem;
      @include border-bottom-left-radius(1rem);
      @include border-bottom-right-radius(1rem);
      min-height: auto;
      h5 {
        color: $text-magenta;
        margin-top: 0.5rem;
        font-size: 1.25rem;
        position: relative;
        font-family: "Poppins SemiBold";
        &:after {
          content: "";
          position: absolute;
          display: block;
          width: 3rem;
          height: 2px;
          background-color: $text-magenta;
          // opacity:0.5;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
    &.clr-1 {
      background-color: $clr-1;
    }
    &.clr-2 {
      background-color: $clr-2;
    }
    &.clr-3 {
      background-color: $clr-3;
    }
    &.clr-4 {
      background-color: $clr-4;
    }
    &.clr-5 {
      background-color: $clr-5;
    }
    &.clr-6 {
      background-color: $clr-6;
    }
    // &:hover {
    //   @include box-shadow(0 2px 5px rgba(16, 30, 54, 0.04));
    //   .card-header {
    //     img {
    //       @include transform(scale(1.05));
    //     }
    //   }
    // }
  }
}
.features-design {
  background-color: $bg-white;
  .card {
    margin-bottom: 1.5rem;
    background-color: $bg-white;
    border: 1px solid $border-gray;
    @include border-radius(1rem);
    @include transition(all 0.7s);
    .card-header {
      position: relative;
      overflow: hidden;
      height: auto;
      padding: 2rem;
      padding-bottom: 0rem;
      text-align: center;
      background-color: $bg-white;
      border-bottom: 0 solid $text-white;
      @include border-top-left-radius(1rem);
      @include border-top-right-radius(1rem);
      @include border-bottom-left-radius(1rem);
      @include border-bottom-right-radius(1rem);
      img {
        background-color: $bg-white;
        max-width: 100%;
        border: 0.5rem solid $bg-white;
        border-bottom: unset;
        @include border-top-left-radius(1rem);
        @include border-top-right-radius(1rem);
        @include border-bottom-right-radius(0rem);
        @include border-bottom-left-radius(0rem);
        @include transition(all 2s cubic-bezier(0.19, 1, 0.22, 1) 0ms);
      }
      &.clr-1 {
        background-color: $clr-1;
        img {
          border-color: $text-warning-light-dark;
        }
      }
      &.clr-2 {
        background-color: $clr-2;
        img {
          border-color: $text-magenta-light-dark;
        }
      }
      &.clr-3 {
        background-color: $clr-3;
        img {
          border-color: $text-danger-light-dark;
        }
      }
      &.clr-4 {
        background-color: $clr-4;
        img {
          border-color: $text-primary-light-dark;
        }
      }
      &.clr-5 {
        background-color: $clr-5;
        img {
          border-color: $text-violet-light-dark;
        }
      }
      &.clr-6 {
        background-color: $clr-6;
        img {
          border-color: $text-pink-light-extra-dark;
        }
      }
    }
    .card-body {
      background-color: $bg-white;
      padding: 1.5rem;
      @include border-bottom-left-radius(1rem);
      @include border-bottom-right-radius(1rem);
      min-height: auto;
      h5 {
        color: $text-magenta;
        margin-top: 0.5rem;
        padding-bottom: 0.25rem;
        font-size: 1.25rem;
        font-family: "Poppins SemiBold";
        position: relative;
        &:after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          width: 4rem;
          height: 0.125rem;
          background-color: $text-magenta;
          opacity: 0.5;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
    // &:hover {
    //   @include box-shadow(0 2px 5px rgba(16, 30, 54, 0.04));
    //   .card-header {
    //     img {
    //       @include transform(scale(1.05));
    //     }
    //   }
    // }
  }
}
.security {
  .card {
    background-color: $text-magenta-light;
    border-color: $text-magenta-light-dark;
    margin-bottom: 1.5rem;
    @include transition(all 0.6s ease);
    .card-body {
      .d-flex {
        .flex-shrink-0 {
          width: 1.5rem;
          margin-right: 1rem;
          .icons {
            background: url(/assets/images/feature/Security/DataSecurityArrow.svg)
              no-repeat;
            background-size: 1.125rem;
            width: 1.5rem;
            height: 1.5rem;
            margin-top: 0.25rem;
            float: left;
          }
        }
      }
    }
    &:hover {
      background-color: $text-magenta-light-dark;
      .card-body {
        .flex-shrink-0 {
          .icons {
            background: url(/assets/images/arrow.svg) no-repeat;
            background-size: 1.125rem;
            width: 1.5rem;
            height: 1.5rem;
            margin-top: 0.25rem;
            float: left;
          }
        }
      }
    }
  }
}
.icon-left {
  &.list-unstyled {
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    text-align: left;
    padding-left: 0rem;
    li {
      position: relative;
      padding-left: 1.5rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-size: 0.9375rem;
      &::before {
        position: absolute;
        content: "";
        left: 0rem;
        top: 0.625rem;
        width: 20px;
        height: 20px;
        background: url(/assets/images/kiteIcon.svg) no-repeat;
      }
    }
  }
  &.icon-magenta {
    li {
      &:before {
        background: url(/assets/images/kiteIcon-mgenta.svg) no-repeat;
      }
    }
  }
  &.icon-success-list {
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    text-align: left;
    padding-left: 0rem;
    li {
      position: relative;
      padding-left: 1.5rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      font-size: 0.9375rem;
      &:before {
        position: absolute;
        content: "";
        left: 0rem;
        top: 0.625rem;
        width: 10px;
        height: 10px;
        background: unset;
        background-color: $text-success;
        @include border-radius(50%);
      }
    }
  }
}
.PhysicalSecurity {
  .card {
    border: 1px solid #efefef;
    @include border-radius(0.5rem);
    .card-body {
      padding: 1.5rem;
      .d-flex {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        .flex-shrink-0 {
          width: 1.5rem;
          margin-right: 1rem;
          .icons {
            background: url(/assets/images/feature/Security/DataSecurityArrow.svg)
              no-repeat;
            background-size: 1.125rem;
            width: 1.5rem;
            height: 1.5rem;
            margin-top: 0.25rem;
            float: left;
          }
        }
        &:hover {
          .flex-shrink-0 {
            .icons {
              background: url(/assets/images/arrow.svg) no-repeat;
              background-size: 1.125rem;
              width: 1.5rem;
              height: 1.5rem;
              margin-top: 0.25rem;
              float: left;
            }
          }
          h5 {
            color: $text-magenta;
          }
        }
      }
    }
    &:hover {
      border: unset;
      @include box-shadow(0px 2px 18px rgba(0, 0, 0, 0.0705882353));
    }
  }
}
.contact-us {
  padding: 3rem 0rem;
  h1 {
    font-size: 2.25rem;
    font-family: "Poppins SemiBold";
  }
  .card {
    background-color: $text-magenta-extralight;
    color: $text-dark-black;
    border: unset;
    @include border-radius(1rem);
    .card-body {
      padding: 1.5rem 2.5rem;
      h2 {
        color: $text-magenta;
        font-family: "Poppins SemiBold";
        font-size: 2.25rem;
        margin-bottom: 1.75rem;
      }
      h3 {
        color: $text-magenta;
        font-family: "Poppins SemiBold";
        margin-bottom: 1.75rem;
        margin-top: 1.5rem;
      }
      .flex-shrink-0 {
        width: 2.25rem;
        img {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
      .flex-grow-1 {
        h4 {
          color: $text-dark-black;
          margin-bottom: 1rem;
          font-family: "Poppins Medium";
          font-size: 1.125rem;
        }
        p {
          font-size: 0.85rem;
          color: $text-black;
        }
      }
    }
  }
}
.working-together {
  width: 100%;
  height: auto;
  padding: 3rem 0rem;
  background: url(/assets/images/Qkrdesk/WorkingTogetherBG.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 0;
  &:before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    background-color: $text-magenta;
  }
  .over-lay {
    z-index: 1;
  }
}
.leftCircleShadow {
  position: relative;
  z-index: 1;
  &:before {
    content: "";
    z-index: -1;
    opacity: 0.8;
    top: 12%;
    left: -3px;
    width: 277px;
    height: 549px;
    position: absolute;
    background-image: url("/assets/images/leftCircleShadow.png");
    background-repeat: no-repeat;
    background-size: 90%, 100%;
  }
}
.RightCircleShadow {
  position: relative;
  z-index: 1;
  &:before {
    content: "";
    z-index: -1;
    opacity: 0.8;
    top: 0;
    right: 0px;
    width: 277px;
    height: 549px;
    position: absolute;
    background-image: url("/assets/images/leftCircleShadow.png");
    background-repeat: no-repeat;
    background-size: 90%, 100%;
    rotate: 180deg;
  }
}
.leftCirclebg {
  position: relative;
  z-index: 1;
  &:before {
    content: "";
    z-index: -1;
    opacity: 0.8;
    top: -7rem;
    left: 0rem;
    width: 216px;
    height: 435px;
    position: absolute;
    background-image: url("/assets/images/bg-circle.png");
    background-repeat: no-repeat;
    background-size: 90%, 100%;
  }
}
.leftCirclebg-bottom {
  position: relative;
  z-index: 1;
  &:before {
    content: "";
    z-index: -1;
    opacity: 0.8;
    bottom: 0rem;
    left: 0rem;
    width: 300px;
    height: 300px;
    position: absolute;
    background-image: url("/assets/images/bg-circle.png");
    background-repeat: no-repeat;
    background-size: 50%, 100%;
  }
}
.left-bg-shadow {
  position: relative;
  z-index: 1;
  &:before {
    content: "";
    z-index: -1;
    opacity: 0.8;
    top: 5rem;
    left: 0rem;
    width: 400px;
    height: 900px;
    position: absolute;
    background-image: url("/assets/images/left-bg-shadow.png");
    background-repeat: no-repeat;
    background-size: 50%, 100%;
  }
}
.escalation-alerts {
  background-color: $text-magenta-light;
  // box-shadow: 0px 0px 8px rgba(110, 110,110, 0.12);
}
.MIS-Reports {
  padding: 2rem 1rem;
  background-color: $text-magenta-light;
  // background: linear-gradient(100deg, $text-warning-light 35%, $text-magenta-light 100%);
  @include border-radius(1.5rem);
}
.customizations-sec {
  &.bg-1 {
    background-color: #f8f2ff;
  }
  &.bg-2 {
    background-color: #ecfafe;
  }
  &.bg-3 {
    background-color: #e9f0fc;
  }
  &.bg-4 {
    background-color: #e9f9f4;
  }
  &.bg-5 {
    background-color: #fff3e8;
  }
  .card {
    .card-body {
      background-color: $bg-white;
      h5 {
        color: $text-magenta-dark;
        &:after {
          background-color: $text-magenta-dark;
        }
      }
    }
  }
}
.workflow-area {
  .container {
    position: relative;
    z-index: 1;
    padding-bottom: 2.25rem;
    &:after {
      content: "";
      bottom: 0rem;
      right: 0rem;
      width: 125px;
      height: 124px;
      position: absolute;
      z-index: -1;
      background-image: url("/assets/images/feature/AlertsNotifications/CirclesVector.png");
      background-repeat: no-repeat;
      rotate: 270deg;
    }
    .card {
      margin-bottom: 1.5rem;
      border: none;
      @include border-radius(2rem);
      box-shadow: unset;
      .card-header {
        text-align: center;
        padding: 2rem;
        padding-bottom: 0rem;
        background-color: transparent;
        border: unset;
        height: 212px;
        img {
          height: 180px;
        }
      }
      .card-body {
        text-align: center;
        padding: 3rem;
        background-color: #fff;
        @include border-radius(2rem);
        @include box-shadow(0px 3px 12px #0000001f);
        h5 {
          position: relative;
          font-size: 1.25rem;
          color: $text-black;
          font-family: "Poppins SemiBold" !important;
          margin-bottom: 2rem;
          &:after {
            content: "";
            left: 0;
            right: 0;
            position: absolute;
            width: 30%;
            height: 2px;
            margin: 0 auto;
            bottom: -5px;
            display: inherit;
            background-color: $text-black;
            opacity: 0.4;
          }
        }
        p {
          font-size: 1.125rem;
          color: $text-black;
        }
      }
      &.enhance-efficiency {
        background-color: $text-pink-light;
        .card-body {
          h5 {
            &:after {
              background-color: $text-pink;
            }
          }
        }
      }
      &.improve-decision {
        background-color: $text-warning-light;
        .card-body {
          h5 {
            &:after {
              background-color: $text-warning-dark;
            }
          }
        }
      }
      &.Eliminate-errors {
        background-color: $text-magenta-light;
        .card-body {
          h5 {
            &:after {
              background-color: $text-magenta;
            }
          }
        }
      }
    }
  }
  &.simple-yet {
    .container {
      padding-bottom: 0rem;
      &:after {
        content: none;
      }
    }
  }
}
.specificallyHR {
  padding-top: 2.5rem;
  background-color: #ff00000f;
  margin-bottom: 2.5rem;
  .img-HR {
    position: relative;
    .img-fluid {
      position: absolute;
      bottom: -1.5rem;
    }
  }
}
.resources-improve {
  background: $text-warning-light;
  .d-flex {
    &.improve-functionality {
      margin-bottom: 1.75rem;
      margin-top: 1.75rem;
      @include box-shadow(0px 0px 8px rgba(0, 0, 0, 0.14));
      @include border-radius(1.45rem);
      .flex-shrink-0 {
        padding: 1rem;
        background-color: $bg-white;
        @include border-top-left-radius(1.45rem);
        @include border-bottom-left-radius(1.45rem);
        .img-fluid {
          max-width: 13.75;
        }
      }
      .flex-grow-1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: $text-magenta-extralight;
        padding: 1rem 1.5rem;
        @include border-top-right-radius(1.45rem);
        @include border-bottom-right-radius(1.45rem);
        h4 {
          margin-bottom: 1rem;
          color: $text-magenta;
          font-size: 1.125rem;
        }
        p {
          font-size: 1rem;
          margin-bottom: 0rem;
        }
        // &.bg1{
        //   background-color: $text-danger-light;
        // }
        // &.bg2{
        //   background-color: $text-pink-light;
        // }
        // &.bg3{
        //   background-color: $text-magenta-light;
        // }
      }
      // &.bg1{
      //   background-color: $text-danger-light;
      // }
      // &.bg2{
      //   background-color: $text-pink-light;
      // }
      // &.bg3{
      //   background-color: $text-magenta-extralight;
      // }
    }
  }
}
.workflow-reminder {
  .card {
    background-color: $text-magenta-light;
    padding: 2.5rem;
    margin-bottom: 1.5rem;
    border: unset;
    @include border-radius(2rem);
    @include box-shadow(0px 0px 10px rgba(0, 0, 0, 0));
    &.bg2 {
      background-color: $clr-1;
    }
    .card-body {
      background-color: $bg-white;
      padding: 2.5rem;
      @include border-radius(1.75rem);
      @include box-shadow(0px 3px 20px rgba(0, 0, 0, 0.051));
      .img-fluid {
        width: 4.25rem;
        height: 4.25rem;
      }
      h3 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }
}
.box-design {
  .card {
    padding: 2rem;
    margin-bottom: 1.5rem;
    border: unset;
    @include border-radius(2rem);
    @include box-shadow(0px 0px 10px rgba(0, 0, 0, 0));
    .card-body {
      background-color: $bg-white;
      padding: 2.5rem;
      @include border-radius(1.75rem);
      @include box-shadow(0px 3px 20px rgba(0, 0, 0, 0.051));
      .img-fluid {
        width: 4.25rem;
        height: 4.25rem;
      }
      h3 {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
      p {
        margin-bottom: 0rem;
      }
    }
    &.bg1 {
      background-color: $clr-2;
      .bottom-border {
        &:after {
          background-color: $text-pink-light-extra-dark;
        }
      }
    }
    &.bg2 {
      background-color: $clr-1;
      .bottom-border {
        &:after {
          background-color: $text-warning-light-dark;
        }
      }
    }
    &.bg3 {
      background-color: $clr-2;
      .bottom-border {
        &:after {
          background-color: $text-pink-light-extra-dark;
        }
      }
    }
  }
}
.compliances {
  background-color: $text-deepsky-dark;
  padding: 2rem;
  @include border-radius(1rem);
  color: #e2f2ff;
  h2 {
    font-family: "Poppins SemiBold";
  }
  .btn-default {
    background-color: $text-white;
    color: $text-deepsky-dark;
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem;
  }
  .card {
    background-color:#4979a1;
    color: $text-white;
    border: unset;
    @include border-radius(1.25rem);
    ul {
      margin-top: 1rem;
    }
  }
}
.box-with-image {
  .card {
    padding: 2rem;
    background-color: $bg-gray-extralight;
    margin-bottom: 1.75rem;
    border: unset;
    @include border-radius(1rem);
    .card-header {
      padding: 0rem;
      background-color: transparent;
      border-bottom: unset;
    }
    .card-body {
      flex: unset;
      padding: 2rem 0rem;
      background-color: transparent;
    }
    .card-footer {
      padding: 1rem;
      background-color: $text-white;
      border-top: unset;
      @include border-radius(0.5rem);
    }
  }
}
.simple-card {
  .bottom-border {
    &:after {
      width: 10rem;
    }
  }
  .card {
    margin-bottom: 2.5rem;
    border: none;
    .card-header {
      padding: 0rem;
      padding-bottom: 2rem;
      background-color: transparent;
      border: unset;
      img {
        height: 4.5rem;
      }
    }
    .card-body {
      padding: 0rem;
      background-color: $bg-white;
      @include border-radius(2rem);
      h5 {
        position: relative;
        font-size: 1.25rem;
        color: $text-magenta;
        // color: $text-black;
        font-family: "Poppins SemiBold" !important;
        margin-bottom: 2rem;
        &:after {
          content: "";
          left: 0;
          right: 0;
          position: absolute;
          width: 30%;
          height: 2px;
          margin: 0;
          bottom: -5px;
          display: inherit;
          background-color: $text-magenta;
          // background-color: $text-black;
          opacity: 0.9;
        }
      }
      p {
        font-size: 1.125rem;
        color: $text-black;
      }
    }
    &.cls-1 {
      .card-body {
        h5 {
          &:after {
            background-color: $clr-7;
          }
        }
      }
    }
    &.cls-2 {
      .card-body {
        h5 {
          &:after {
            background-color: $clr-8;
          }
        }
      }
    }
    &.cls-3 {
      .card-body {
        h5 {
          &:after {
            background-color: $clr-9;
          }
        }
      }
    }
    &.cls-4 {
      .card-body {
        h5 {
          &:after {
            background-color: $clr-10;
          }
        }
      }
    }
    &.cls-5 {
      .card-body {
        h5 {
          &:after {
            background-color: $clr-11;
          }
        }
      }
    }
    &.cls-6 {
      .card-body {
        h5 {
          &:after {
            background-color: $clr-12;
          }
        }
      }
    }
    &.cls-7 {
      .card-body {
        h5 {
          &:after {
            background-color: $clr-13;
          }
        }
      }
    }
  }
}
.mobile-design {
  padding-top: 2.5rem;
  background-color: $text-magenta-light-dark;
  position: relative;
}
.question-design {
  .card {
    border: unset;
    .card-space {
      padding: 1.5rem 1.5rem 1.5rem 6.5rem;
      font-size: 0.7rem;
    }
    &.q_img {
      width: 100%;
      height: 155px;
      margin-bottom: 1.5rem;
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
      }
    }
    &.q_img_1 {
      &:before {
        background: url(/assets/images/Qkrtenders/q_img_1.svg) no-repeat;
        background-size: 100%;
      }
    }
    &.q_img_2 {
      &:before {
        background: url(/assets/images/Qkrtenders/q_img_2.svg) no-repeat;
        background-size: 100%;
      }
    }
    &.q_img_5 {
      &:before {
        background: url(/assets/images/Qkrtenders/q_img_3.svg) no-repeat;
        background-size: 100%;
      }
    }
    &.q_img_4 {
      &:before {
        background: url(/assets/images/Qkrtenders/q_img_4.svg) no-repeat;
        background-size: 100%;
      }
    }
    &.q_img_3 {
      &:before {
        background: url(/assets/images/Qkrtenders/q_img_5.svg) no-repeat;
        background-size: 100%;
      }
    }
    &.q_img_6 {
      &:before {
        background: url(/assets/images/Qkrtenders/q_img_6.svg) no-repeat;
        background-size: 100%;
      }
    }
  }
}
.squar_bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  &::before {
    z-index: -1;
    top: -1rem;
    left: 0rem;
    content: "";
    position: absolute;
    width: 260px;
    height: 260px;
    background: url(/assets/images/qkrsales/squar_bg.svg) no-repeat;
    background-size: 115% 90%;
  }
  &.squar_bg-tender {
    &::before {
      background: url(/assets/images/Qkrtenders/squar_bg-tender.svg) no-repeat;
      background-size: 115% 90%;
    }
  }
  .squar-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .squar {
      width: 120px;
      padding: 0rem 1rem;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      z-index: 2;
      &:before {
        z-index: -1;
        position: absolute;
        content: "";
        background-color: #fff;
        width: 110px;
        height: 110px;
        border-radius: 1.25rem;
        box-shadow: 0px 0px 20px rgba(124, 144, 151, 0.22);
      }
      .img-icon {
        width: 2rem;
        height: 2rem;
        margin: 0 auto;
      }
      h5 {
        font-size: 0.65rem;
        padding-top: 1rem;
        padding-bottom: 0rem;
        margin-bottom: 0rem;
      }
    }
    &.squar-column {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      &:before {
        z-index: -1;
      }
    }
  }
}
.hrms-faq{
  padding: 1rem 0rem 2.5rem 0rem;
  .accordion{
    .accordion-item{
      margin-bottom: 1.5rem;
      border-bottom: 1px solid $text-slateblue-light;
      border-radius: 0.375rem !important;
      &:not(:first-of-type){
        border-top: 1px solid $text-slateblue-light;
      }
      .accordion-button{
        font-size: 1.135rem;
        align-items: flex-start;
        border-radius: 0.375rem !important;
        &:not(.collapsed){
          background-color: $bg-white;
          color: $text-magenta;
          box-shadow: unset;
          &::after{
            background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%2396006B%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e');
          }
        }
      }
      h4{
        font-size: 1rem;
        font-family: "Poppins SemiBold";
      }
      .ul-icon li.tick-icon-magenta{
        &::before{
          background-size: 90%;
        }

      }
    }
  }
}
@media (min-width: 320px) {
  .question-design {
    .card {
      border: unset;
      .card-space {
        padding: 1.5rem 1.5rem 1.5rem 7.5rem;
        font-size: 0.75rem;
      }
      &.q_img {
        height: 190px;
      }
    }
  }
  .squar_bg {
    &::before {
      top: -1.5rem;
      left: 0rem;
      width: 280px;
      height: 300px;
      background-size: 120% 80%;
    }
    &.squar_bg-tender {
      &::before {
        background-size: 120% 80%;
      }
    }
    .squar-area {
      .squar {
        width: 130px;
        &:before {
          width: 120px;
          height: 120px;
        }
        .img-icon {
          width: 2rem;
          height: 2rem;
        }
        h5 {
          font-size: 0.7rem;
        }
      }
    }
  }
}
@media (min-width: 380px) {
  .question-design {
    .card {
      border: unset;
      .card-space {
        padding: 1.5rem 1.5rem 1.5rem 9rem;
        font-size: 0.9rem;
      }
      &.q_img {
        height: 210px;
      }
    }
  }
  .squar_bg {
    &::before {
      top: -2rem;
      left: 0rem;
      width: 360px;
      height: 360px;
      background-size: 110% 80%;
    }
    &.squar_bg-tender {
      &::before {
        background-size: 110% 80%;
      }
    }
    .squar-area {
      .squar {
        width: 130px;
        &:before {
          width: 120px;
          height: 120px;
        }
        .img-icon {
          width: 2rem;
          height: 2rem;
        }
        h5 {
          font-size: 0.7rem;
        }
      }
    }
  }
}
@media (min-width: 420px) {
  .question-design {
    .card {
      border: unset;
      .card-space {
        padding: 1.5rem 1.5rem 1.5rem 9rem;
        font-size: 0.9rem;
      }
      &.q_img {
        height: 230px;
      }
    }
  }
  .squar_bg {
    &::before {
      top: -2rem;
      left: 0rem;
      width: 350px;
      height: 350px;
      background-size: 120% 95%;
    }
    &.squar_bg-tender {
      &::before {
        background-size: 120% 95%;
      }
    }
    .squar-area {
      .squar {
        width: 170px;
        &:before {
          width: 155px;
          height: 155px;
        }
        .img-icon {
          width: 2.5rem;
          height: 2.5rem;
        }
        h5 {
          font-size: 0.9rem;
        }
      }
    }
  }
}
@media (min-width: 480px) {
  .question-design {
    .card {
      border: unset;
      .card-space {
        padding: 1.5rem 1.5rem 1.5rem 11rem;
      }
      &.q_img {
        height: 260px;
      }
    }
  }
  .squar_bg {
    &::before {
      top: -3.5rem;
      left: 0rem;
      width: 460px;
      height: 360px;
      background-size: 120% 95%;
    }
    &.squar_bg-tender {
      &::before {
        background-size: 120% 95%;
      }
    }
    .squar-area {
      .squar {
        width: 180px;
        &:before {
          width: 160px;
          height: 160px;
        }
        h5 {
          font-size: 0.9rem;
        }
      }
    }
  }
}
@media (min-width: 540px) {
  .question-design {
    .card {
      border: unset;
      .card-space {
        padding: 1.5rem 1.5rem 1.5rem 12rem;
        font-size: 1rem;
      }
      &.q_img {
        height: 285px;
      }
    }
  }
}
@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 640px;
  }
  .question-design {
    .card {
      .card-space {
        padding: 1.5rem 1.5rem 1.5rem 9rem;
        font-size: 0.9rem;
      }
      &.q_img {
        width: 360px;
        height: 195px;
      }
    }
  }
  .squar_bg {
    &::before {
      top: -3.5rem;
      left: 0rem;
      width: 550px;
      height: 425px;
      background-size: 110% 80%;
    }
    &.squar_bg-tender {
      &::before {
        background-size: 110% 80%;
      }
    }
    .squar-area {
      .squar {
        width: 180px;
        &:before {
          width: 160px;
          height: 160px;
        }
        h5 {
          font-size: 0.9rem;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 750px;
  }
  // apps-section
  .apps-section {
    .card {
      .card-body {
        min-height: 11rem;
      }
    }
    &.apps-hr {
      .card {
        .card-body {
          min-height: 12.25rem;
        }
      }
    }
    &.apps-sales {
      .card {
        .card-body {
          min-height: 12.25rem;
        }
      }
    }
  }
  .platformfeatures_home {
    .card {
      min-height: 20rem;
      .card-body {
        h4 {
          padding: 0rem;
        }
      }
    }
  }
  .question-design {
    .card {
      .card-space {
        padding: 1.5rem 1.5rem 1.5rem 9rem;
      }
      &.q_img {
        width: 360px;
        height: 190px;
      }
    }
  }
  .squar_bg {
    &::before {
      top: -4.5rem;
      left: 0rem;
      width: 581px;
      height: 373px;
      background-size: 135% 100%;
    }
    &.squar_bg-tender {
      &::before {
        background-size: 135% 100%;
      }
    }
    .squar-area {
      .squar {
        width: 220px;
        padding: 0rem 1.5rem;
        &:before {
          width: 190px;
          height: 190px;
          border-radius: 1.85rem;
        }
        h5 {
          font-size: 1rem;
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 980px;
  }
  .hero-top-banner {
      padding-top: 5rem;
      &::before{
        background-size: 100%;
      }
      .banner-heading {
        margin-top: 2.5rem;
        p {
          margin: 1.75rem 0rem;
        }
        .bottom-img{
          padding-top: 7rem;
        }
      }
      .apps {
        margin-top: 1.5rem;
      } 
  }
  .apps-section {
    .card {
      .card-body {
        min-height: 11rem;
      }
    }
    &.apps-hr {
      .card {
        .card-body {
          min-height: 12.5rem;
        }
      }
    }
    &.apps-sales {
      .card {
        .card-body {
          min-height: 20.5rem;
        }
      }
    }
  }
  .accountable {
    .d-flex {
      .flex-shrink-1 {
        &.text-right {
          &:before {
            background-color: $text-warning-dark;
          }
        }
        &.text-left {
          &:before {
            background-color: $text-warning-dark;
          }
        }
        &:nth-child(odd) {
          .line:before {
            background-color: $text-warning-dark;
          }
        }
        &:nth-child(even) {
          .line:after {
            background-color: $text-warning-dark;
          }
        }
      }
      .secure_icon {
        border: 10px solid $text-warning-dark;
        background-color: $bg-white;
      }
    }
  }
  .content-box{
    padding-top: 10rem;
    &.left-topbg {
      position: relative;
      &::before {
        top: 5rem;
        left: 0;
        content: "";
        position: absolute;
        background-image: url("/assets/images/lefttopbg.png");
        background-repeat: no-repeat;
        background-size: 90% 100%;
        width: 646px;
        height: 185px;
      }
    }
    .header-title{
      font-size: 2rem;
      margin-bottom: 2rem;
    }
  }
  .content-top{
    min-height: calc(100vh - 13rem);
    &.top-bg {
      position: relative;
      z-index: 1;
      &::before {
        z-index: -1;
        top: -5.95rem;
        left: 0;
        content: "";
        position: absolute;
        background-image: url("/assets/images/lefttopbg.png");
        background-repeat: no-repeat;
        background-size: 90% 100%;
        width: 646px;
        height: 185px;
      }
    }
    .header-title {
      font-size: 2rem;
    }
    .breadcrumb{
      margin-top: 0rem;
    }
    .btn {
      margin-top: 3rem;
      margin-bottom: 1rem;
    }
  }
  .window-top-banner {
    padding-top: 7rem;
    .w-height {
      min-height: calc(100vh - 132px);
    }
    &.bg {
      position: relative;
      z-index: 1;
      &:before {
        content: "";
        position: absolute;
        right: 0;
        background: url(/assets/images/FreeTrailBG.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align: center;
        width: 68%;
        height: 100vh;
        z-index: -1;
      }
    }
    .header-title {
      font-size: 2rem;
    }
  }
  .box {
    &:before {
      width: 550px;
    }
    &:after {
      width: 450px;
    }
  }
  .workflow-area {
    margin-bottom: 3rem;
    .container {
      .card {
        .card-body {
          min-height: 18rem;
        }
      }
    }
  }
  .specificallyHR {
    padding-top: 2rem;
    background: linear-gradient(
      90deg,
      $text-danger-light 58%,
      $text-danger-dark 42%
    );
  }
  .mobile-design {
    padding-top: 2rem;
    background: linear-gradient(
      90deg,
      $text-pink-light 76.5%,
      $text-pink-dark 23.5%
    );
    &:after {
      position: absolute;
      content: "";
      right: 3rem;
      bottom: 5rem;
      height: 6rem;
      width: 2rem;
      background: url("/assets/images/PolygonMobile.png");
      background-size: 97%;
      display: inline-block;
    }
  }
  .compliances {
    @include border-radius(2.5rem);
    padding: 5rem;
  }
  .question-design {
    .card {
      .card-space {
        padding: 1.5rem 1.5rem 1.5rem 9rem;
      }
      &.q_img {
        width: 380px;
        height: 200px;
        margin-bottom: 2.5rem;
      }
    }
  }
  .squar_bg {
    &::before {
      top: -4.5rem;
      left: 0rem;
      width: 471px;
      height: 373px;
      background-size: 135% 100%;
    }
    &.squar_bg-tender {
      &::before {
        background-size: 135% 100%;
      }
    }
    .squar-area {
      .squar {
        width: 220px;
        padding: 0rem 1.5rem;
        &:before {
          width: 190px;
          height: 190px;
          border-radius: 1.85rem;
        }
        h5 {
          font-size: 1rem;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1180px;
  }
  .img-fluid-95 {
    max-width: 95%;
    height: auto;
  }
  .hero-top-banner {
      .banner-heading {
        .btn{
          margin-top: 1.5rem;
        }
        .bottom-img{
          padding-top: 4rem;
        }
      }
  }
  .apps-section {
    .card {
      .card-body {
        min-height: 11rem;
      }
    }
    &.apps-hr {
      .card {
        .card-body {
          min-height: 12rem;
        }
      }
    }
    &.apps-sales {
      .card {
        .card-body {
          min-height: 18rem;
        }
      }
    }
  }
  .question-design {
    .card {
      .card-space {
        padding: 1.5rem 1.5rem 1.5rem 9rem;
      }
      &.q_img {
        width: 370px;
        height: 200px;
        margin-bottom: 2.5rem;
      }
    }
  }
  .squar_bg {
    &::before {
      top: -4.5rem;
      left: 0rem;
      width: 471px;
      height: 373px;
      background-size: 115% 100%;
    }
    &.squar_bg-tender {
      &::before {
        background-size: 115% 100%;
      }
    }
    .squar-area {
      .squar {
        width: 220px;
        padding: 0rem 1.5rem;
        &:before {
          width: 190px;
          height: 190px;
          border-radius: 1.85rem;
        }
        h5 {
          font-size: 1rem;
        }
      }
    }
  }
}
@media (min-width: 1400px) {
  body {
    font-size: 1rem;
  }
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
  .customizable-width {
    max-width: 100%;
    .row {
      margin-left: -1.5rem;
      [class*=" col-"] {
        padding-left: 0rem;
        padding-right: 0rem;
      }
    }
  }
  .hero-top-banner {
    .banner-heading {
      .bottom-img{
        padding-top: 2.5rem;
        .img-fluid {
          max-width: calc(100% + 280px);
          // height: 300px;
        }
      }
    }
  }
  .apps-section {
    .card {
      .card-body {
        min-height: 12.5rem;
        p {
          margin-bottom: 0rem;
        }
      }
      .card-footer {
        .arrowLink {
          &::before {
            top: 0.15rem;
          }
        }
      }
    }
    &.apps-hr {
      .card {
        .card-body {
          min-height: 14rem;
        }
      }
    }
    &.apps-sales {
      .card {
        .card-body {
          min-height: 20rem;
        }
      }
    }
  }
  .heading-title {
    .under-line {
      font-size: 2rem;
    }
  }
  .justify-between {
    justify-content: space-between !important;
  }
  .question-design {
    .card {
      .card-space {
        font-size: 1rem;
        padding: 1.5rem 1.5rem 1.5rem 10rem;
      }
      &.q_img {
        width: 415px;
        height: 225px;
        margin-bottom: 2.5rem;
      }
    }
  }
  .squar_bg {
    &:before {
      top: -7em;
      left: 0rem;
      width: 560px;
      height: 560px;
      background-size: 120% 100%;
    }
    &.squar_bg-tender {
      &::before {
        background-size: 120% 100%;
      }
    }
    .squar-area {
      .squar {
        width: 260px;
        height: 160px;
        &:before {
          width: 230px;
          height: 230px;
        }
        .img-icon {
          width: 3rem;
          height: 3rem;
        }
        h5 {
          font-size: 1.25rem;
        }
      }
    }
  }
}
@media (min-width: 1600px) {
}
@media (min-width: 1900px) {
  .p-right-lg-1 {
    padding-right: 1rem !important;
  }
  .p-left-lg-1 {
    padding-left: 1rem !important;
  }
  .p-right-lg-2 {
    padding-right: 2rem !important;
  }
  .p-left-lg-2 {
    padding-left: 2rem !important;
  }
  .p-right-lg-3 {
    padding-right: 3rem !important;
  }
  .p-left-lg-3 {
    padding-left: 3rem !important;
  }
  .p-right-lg-4 {
    padding-right: 4rem !important;
  }
  .p-left-lg-4 {
    padding-left: 4rem !important;
  }
  .p-right-lg-5 {
    padding-right: 5rem !important;
  }
  .p-left-lg-5 {
    padding-left: 5rem !important;
  }
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1632px;
  }
  .customizable-width {
    max-width: 100%;
  }
  .qkrModule {
    .qkrimg {
      width: 4rem;
      height: 4rem;
      background-size: 2.5rem auto !important;
    }
  }
  .hero-top-banner {
      padding-top: 6rem;
      min-height:100vh;
      .banner-heading {
        margin-top: 3.5rem;
        h1 {
          font-size: 2.75rem;
          margin-top: 2.5rem;
          margin-bottom: 2.5rem;
        }
        p {
          font-size: 1.65rem;
        }
        .btn{
          margin-top: 2rem;
        }
        .bottom-img{
          padding-top: 5rem;
        }
      }
      .apps {
        margin-top: 3rem;
        padding-left: 2rem;
        .card {
          .card-body {
            padding: 2rem 2rem 1rem 2rem;
            .apps-heading{
              margin-bottom: 2.5rem;
            }
            p {
              font-size: 1.25rem;
            }
            .d-flex {
              margin-top: 1rem;
              .flex-grow-1 {
                h5 {
                  font-size: 1.25rem;
                  margin-bottom: 0.5rem;
                }
                p {
                  font-size: 1rem;
                }
              }
              .qkrimg {
                width: 4rem;
                height: 4rem;
                background-size: 2.25rem auto !important;
              }
              &:hover {
                .qkrimg {
                  width: 4rem;
                  height: 4rem;
                  background-size: 2.5rem auto !important;
                }
              }
            }
          }
          .card-footer {
            padding: 1.5rem 0rem;
            margin: 0rem 2.5rem;
            .allproducts {
              font-size: 1rem;
            }
          }
        }
      }
  }
  // Apps
  .apps-section {
    .card {
      padding: 1rem;
      margin: 0.5rem 0.5rem 1rem  0rem;
      .card-body {
        font-size: 1rem;
        min-height: 14rem;
        h4 {
          font-size: 1.5rem;
          margin-top: 0rem;
        }
      }
      &:hover {
        .qkrimg {
          width: 4rem;
          height: 4rem;
          background-size: 2.5rem auto !important;
        }
      }
    }
    &.apps-hr {
      padding: 3.5rem 0rem;
      .card {
        .card-body {
          min-height: 15rem;
        }
      }
    }
    &.apps-sales {
      padding: 3.5rem 0rem;
      .card {
        .card-body {
          min-height: 20rem;
          h4 {
            font-size: 1.25rem;
            margin-top: 0rem;
          }
        }
      }
    }
  }
  .infrastructure {
    p {
      font-size: 1.25rem;
    }
    .d-flex {
      margin-top: 0.75rem;
      .icons {
        padding-top: 0.5rem;
        width: 1.25rem;
      }
    }
  }
  .platformfeatures_home {
    .card {
      min-height: 20.813rem;
      margin-bottom: 3rem;
      .card-body {
        h4 {
          font-size: 1.25rem;
          width: 17rem;
        }
      }
    }
  }
  .content-box{
    padding-top: 12rem;
    &.left-topbg {
      &::before {
        top: 6rem;
        background-repeat: no-repeat;
        background-size: 90% 100%;
        width: 646px;
        height: 260px;
      }
    }  
    .header-title {
      font-size: 2.5rem;
    }
    .header-text {
      font-size: 1.375rem;
    }
    .header-desc {
      font-size: 1.125rem;
    }
    .btn {
      margin-top: 3.5rem;
    }
  }
  .content-top{
    &.top-bg {
      &::before {
        top:-5.5rem;
        background-repeat: no-repeat;
        background-size: 90% 100%;
        width: 646px;
        height: 260px;
      }
    } 
    .header-title {
      font-size: 2.5rem;
    }
    .header-text {
      font-size: 1.375rem;
    }
    .header-desc {
      font-size: 1.125rem;
    }
    .btn {
      margin-top: 3.5rem;
    }
    .img-top{
      margin-top: 2rem;
    }
  }
  .window-top-banner {
    .header-title {
      font-size: 2.5rem;
    }
    .header-text {
      font-size: 1.375rem;
    }
    .header-desc {
      font-size: 1.125rem;
    }
    .btn {
      margin-top: 3.5rem;
    }
  }
  .workflow-area {
    .container {
      &:after {
        right: -2.25rem;
      }
      .rows {
        margin-left: -4.5rem;
        margin-right: -4.5rem;
        .col-lg-4 {
          padding-left: 4.5rem;
          padding-right: 4.5rem;
        }
      }
      .card {
        .card-body {
          min-height: 18rem
          h5 {
            font-size: 1.25rem;
          }
        }
      }
    }
  }
  .box-design {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    .col-lg-4 {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  .question-design {
    .card {
      .card-space {
        font-size: 1.125rem;
        padding: 1.5rem 1.5rem 1.5rem 11rem;
      }
      &.q_img {
        width: 460px;
        height: 245px;
        margin-bottom: 2.5rem;
      }
    }
  }
  .squar_bg {
    &:before {
      top: -7em;
      left: 0rem;
      width: 660px;
      height: 560px;
      background-size: 120% 100%;
    }
    &.squar_bg-tender {
      &::before {
        background-size: 120% 100%;
      }
    }
    .squar-area {
      .squar {
        width: 260px;
        height: 160px;
        &:before {
          width: 230px;
          height: 230px;
        }
        .img-icon {
          width: 3rem;
          height: 3rem;
        }
        h5 {
          font-size: 1.25rem;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .order-m-0 {
    order: 0 !important;
  }
  .order-m-1 {
    order: 1 !important;
  }
  .mb-1-xs {
    margin-bottom: 1rem !important;
  }
  .mb-2-xs {
    margin-bottom: 2rem !important;
  }
  .mb-3-xs {
    margin-bottom: 3rem !important;
  }
  .mb-4-xs {
    margin-bottom: 4rem !important;
  }
  .mb-5-xs {
    margin-bottom: 5rem !important;
  }
  .mt-1-xs {
    margin-top: 1rem !important;
  }
  .mt-2-xs {
    margin-top: 2rem !important;
  }
  .mt-3-xs {
    margin-top: 3rem !important;
  }
  .mt-4-xs {
    margin-top: 4rem !important;
  }
  .mt-5-xs {
    margin-top: 5rem !important;
  }
  .mr-5-xs {
    margin-right: 5rem !important;
  }
  .ml-3-xs {
    margin-left: 3rem !important;
  }
  .ml-4-xs {
    margin-left: 4rem !important;
  }
  .ml-5-xs {
    margin-left: 5rem !important;
  }
  .video-auto-play {
    &.radius-3 {
      @include border-radius(1rem);
    }
  }
  .wrapper-bg-img {
    height: 220px;
    &:before {
      width: 100%;
      height: 100%;
      @include transform(skew(210deg) translate(-35%));
    }
  }
  .wrapper-area {
    padding: 1.5rem 0rem;
  }
  .workflow-reminder {
    .card {
      text-align: center;
      padding: 0rem;
      margin-bottom: 1.75rem;
      .card-header {
        padding-bottom: 1.5rem;
        @include border-radius(1.75rem);
      }
      .card-body {
        background-color: transparent;
        .bottom-border {
          &:after {
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .box-design {
    .card {
      text-align: center;
      padding: 0rem;
      margin-bottom: 1.75rem;
      .card-header {
        @include border-radius(1.75rem);
        padding-bottom: 1.5rem;
      }
      .card-body {
        background-color: transparent;
        min-height: auto;
        .bottom-border {
          &:after {
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .leftCirclebg {
    &:before {
      top: -7rem;
    }
  }
}
@media (max-width: 767px) {
  .wrapper-bg-img {
    height: 125px;
  }
  .resources-improve {
    .d-flex {
      &.improve-functionality {
        display: table !important;
        .flex-shrink-0 {
          width: auto;
          padding: 1rem 1.5rem;
          @include border-radius(1.45rem);
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .justify-content-xs-center {
    justify-content: center !important;
  }
}
@media (max-width: 480px) {
}
