// navigation
.navbar-toggler {
  padding-left: 0rem;
  padding-right: 0rem;
  margin-right: 1rem !important;
  margin-left: 0rem !important;
  border-color: transparent;
  .navbar-toggler-icon {
    width: 1.5em;
    height: 1.5em;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(150, 0, 107, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(150, 0, 107, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    -ms-background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(150, 0, 107, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    -webkit-background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(150, 0, 107, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    -moz-background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(150, 0, 107, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    -o-background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(150, 0, 107, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
  &:focus, &:hover, &:active {
    color: rgba(0, 0, 0, 0.1);
    outline: none;
  }
}
.navbar-brand {
  font-family: "Poppins SemiBold";
  color: $text-black;
  font-size: 1.75rem;
  &.qkr-biz {
    background: url(/assets/images/appicon/Qkrbiz.svg) no-repeat;
    background-position: left;
    background-size: 2rem 3.25rem;
    padding-left: 34px;
  }
  &.qkr-hr {
    background: url(/assets/images/appicon/QkrHR.svg) no-repeat;
    background-position: left;
    padding-left: 40px;
    background-size: 2rem 3.5rem;
  }
  &.qkr-visit {
    background: url(/assets/images/appicon/Qkrvisit.svg) no-repeat;
    background-size: 2rem 3.25rem;
    padding-left: 40px;
  }
  &.qkr-expense {
    background: url(/assets/images/appicon/Qkrexpense.svg) no-repeat;
    background-size: 2rem 3.25rem;
    padding-left: 40px;
  }
  &.qkr-desk {
    background: url(/assets/images/appicon/Qkrdesk.svg) no-repeat;
    background-size: 2rem 3.25rem;
    padding-left: 40px;
  }
  &.qkr-sales {
    background: url(/assets/images/appicon/Qkrsales.svg) no-repeat;
    background-size: 2rem 3rem;
    padding-left: 40px;
  }
  &.qkr-tenders {
    background: url(/assets/images/appicon/Qkrtenders.svg) no-repeat;
    background-size: 2rem 3.25rem;
    padding-left: 40px;
  }
  &.qkr-task {
    background: url(/assets/images/appicon/Qkrtask.svg) no-repeat;
    background-size: 2rem 3.25rem;
    padding-left: 40px;
  }
  &.qkr-projects {
    background: url(/assets/images/appicon/Qkrprojects.svg) no-repeat;
    background-size: 2rem 3.25rem;
    padding-left: 40px;
  }
  &:hover, &:focus, &:active {
    color: $text-black;
  }
}
.navbar-nav {
   scrollbar-width: thin;
   scrollbar-color: rgba(165, 169, 175, 1) transparent;
    &::-webkit-scrollbar {
      width: 12px; /* Width of the scrollbar */
    } 
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }  
    &::-webkit-scrollbar-thumb {
      background: rgba(165, 169, 175, 1); /* Scrollbar thumb */
      border-radius: 10px; /* Roundness of the scrollbar */
      box-shadow: inset 0 0 6px rgba(165, 169, 175, 1); /* Inner shadow effect */
    }  
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(98, 109, 123, 1); 
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgb(243, 242, 242);
    }

  &.open, &.active, &:hover, &:hover {
    color: $text-magenta !important;
  }
  .nav-link {
    cursor: pointer;
    font-size: 1rem;
    color: $text-black !important;
    &.active, &:hover, &:active, &:focus {
      color: $text-magenta !important;
    }
  }
  .dropdown {
    .dropdown-menu {
      border: unset;
      .nav-link {
        font-size: 0.95rem;
        // &.active, &:hover, &:active, &:focus {
        //   color: $text-magenta !important;
        // }
      }
      .dropdown-item {
        font-size: 0.95rem;
        &.active, &:hover, &:active, &:focus {
          background-color: $text-magenta-light;
          color: $text-magenta !important;
          @include border-radius(0.25rem);
        }
      }
    }
    .dropdown-toggle {
      width: 100%;
      position: relative;
      &:after {
        position: absolute;
        display: inline-block;
        margin-top: 0.125rem;
        margin-left: 0.5rem;
        font-size: 0.85rem;
        color: $text-magenta;
        font-family: "Font Awesome 6 Free";
        content: "\f078";
        font-weight: 600;
        border: unset;
        vertical-align: baseline;
        @include transition(all 0.7s);
      }
      &:hover::after {
        color: $text-magenta;
      }
    }
  }
  .mega-menu {
    .dropdown-menu {
      .row {
        margin-right: 0px;
        margin-left: 0px;
        .col-lg-6 {
          padding-right: 0px;
          padding-left: 0px;
          h4 {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            padding-left: 0.25rem;
            font-family: "Poppins Medium";
            font-size: 1rem;
            color: $text-black;
          }
          .logo-part {
            display: table;
            width: 100%;
            vertical-align: top;
            white-space: normal;
            .logo {
              display: table-cell;
              width: 2.5rem;
              height:auto;
              @include transform(scale(1));
              &.qkrtenders {
                background: url(/assets/images/appicon/Qkrtenders.svg) no-repeat;
                background-size: 85% 80%;
              }
              &.qkrsales {
                background: url(/assets/images/appicon/Qkrsales.svg) no-repeat;
                background-size: 80% 80%;
              }
              &.qkrexpense {
                background: url(/assets/images/appicon/Qkrexpense.svg) no-repeat;
                background-size: 80% 80%;
              }
              &.qkrprojects {
                background: url(/assets/images/appicon/Qkrprojects.svg) no-repeat;
                background-size: 80% 80%;
              }
              &.qkrdesk {
                background: url(/assets/images/appicon/Qkrdesk.svg) no-repeat;
                background-size: 80% 80%;
              }
              &.qkrhr {
                background: url(/assets/images/appicon/QkrHR.svg) no-repeat;
                background-size: 75% 50%;
              }
              &.qkrvisit {
                background: url(/assets/images/appicon/Qkrvisit.svg) no-repeat;
                background-size: 80% 80%;
              }
              &.qkrtask {
                background: url(/assets/images/appicon/Qkrtask.svg) no-repeat;
                background-size: 80% 80%;
              }
              &.qkrinventory {
                background: url(/assets/images/appicon/Qkrinventory.svg) no-repeat;
                background-size: 80% 80%;
              }
              // platformfeature
              &.pfview {
                background: url(/assets/images/platformFeature/color/Views.svg)no-repeat;
                background-size: 80% 55%;
                opacity: 0.8;
              }
              &.pfSpreadsheetFeatures {
                background: url(/assets/images/platformFeature/color/SpreadsheetFeatures.svg)no-repeat;
                background-size:80% 55%;
                opacity: 0.8
              }
              &.pfAlertsNotification {
                background: url(/assets/images/platformFeature/color/AlertsNotification.svg) no-repeat;
                background-size:75% 75%;
                opacity: 0.8

              }
              &.pfWorkflowAutomation {
                background: url(/assets/images/platformFeature/color/WorkflowAutomation.svg) no-repeat;
                background-size: 80% 70%;
                opacity: 0.8
              }
              &.pfCustomizations {
                background: url(/assets/images/platformFeature/color/Customizations.svg) no-repeat;
                background-size: 75% 75%;
                opacity: 0.8;
              }
              &.pfDocuments {
                background: url(/assets/images/platformFeature/color/Documents.svg) no-repeat;
                background-size: 65% 75%;
                opacity: 0.8
              }
              &.pfSecurity {
                background: url(/assets/images/platformFeature/color/Security.svg) no-repeat;
                background-size: 70% 70%;
                opacity: 0.8
              }
              &.pfBIAnalytics {
                background: url(/assets/images/platformFeature/color/BIAnalytics.svg) no-repeat;
                background-size: 90% 60%;
                opacity: 0.8;
              }
              &.pfBoards {
                background: url(/assets/images/platformFeature/color/Boards.svg) no-repeat;
                background-size: 75% 70%;
                opacity: 0.8;
              }
              &.pfMobileApp {
                background: url(/assets/images/platformFeature/color/MobileApp.svg) no-repeat;
                background-size: 100% 60%;
                opacity: 0.8;
              }
            
            }
            .titles {
              display: table-cell;
              vertical-align: middle;
              width: auto;
              padding-left: 0.5rem;
              h5 {
                margin-top: 0rem;
                margin-bottom: 0.5rem;
                font-family: "Poppins Medium";
                font-size: 0.95rem;
                color: $text-black;
                &.active, &:hover, &:active, &:focus {
                  color: $text-magenta !important;
                }
              }
              p {
                margin: 0px;
                padding: 0px;
                font-size: 0.8rem;
                color: $text-black;
                &.xs-d-block{
                    display: none;
                }
              }
            }
          }
          &.product-feature {
            h4 {
              display: none;
            }
          }
        }
      }
    }
  }
}
.navbar-light {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  background-color: $bg-white !important;
  @include box-shadow(0px 1px 10px rgba(150, 0, 107, 0.1490196078));
  &.nav-desktop-hide {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  &.nav-scroll {
    .navbar-brand {
      font-size:1.325rem;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      &:hover, &:focus, &:active{
          color: $text-magenta;
      } 
    }
    .navbar-nav {
      overflow: auto;
      .nav-link {
        font-size: 1rem;
        white-space: nowrap;
      }
    }
    .nav-item-scroll-show{
      display: none;
    }
  }
  .btn-login{
    display: block
  }
}
.offcanvas{
  &.offcanvas-start{
    width: 80%;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    .offcanvas-header {
      margin-bottom: 0.125rem;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      .offcanvas-title {
        margin-bottom: 0rem;
        font-size: 1.5rem;
        .navbar-brand {
          padding: 0.5rem 0.5rem 0.5rem 2.25rem;
        }
      }
      .btn-close{
        margin-right: 0.25rem;
      }
    }
    .offcanvas-body {
      scrollbar-width: thin;
      scrollbar-color: rgba(165, 169, 175, 1) transparent;
        &::-webkit-scrollbar {
          width: 12px; /* Width of the scrollbar */
        } 
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }  
        &::-webkit-scrollbar-thumb {
          background: rgba(165, 169, 175, 1); /* Scrollbar thumb */
          border-radius: 10px; /* Roundness of the scrollbar */
          box-shadow: inset 0 0 6px rgba(165, 169, 175, 1); /* Inner shadow effect */
        }  
        &::-webkit-scrollbar-thumb:hover {
          background: rgba(98, 109, 123, 1); 
        }
        &::-webkit-scrollbar-thumb:window-inactive {
          background: rgb(243, 242, 242);
        }
    } 
  }
}
.navbar-light, 
.offcanvas{
  .nav-form{
      .btn {
        width: 5.5rem;
        cursor: pointer;
        font-size: 0.8125rem;
        padding: 0.5625rem 0.75rem;
        @include border-radius(0.25rem);
      }
      .btn-outline-magenta {
        margin-right: 1rem;
      }
      .btn-magenta {
        &:hover,
        &:focus,
        &:active,
        &:active:focus,
        &.disabled {
          color: $text-white;
          @include box-shadow(none);
        }
      }
  }

}

@media (min-width: 576px) {

  .offcanvas{
    &.offcanvas-start{
      width: 360px;
    }
  }

}
@media (min-width: 768px) {

}
@media (min-width: 992px) {
  .navbar-light {
    .navbar-nav {
      .nav-link {
        font-size: 1.063rem;
        padding: 1.75rem 1.25rem;
      }
      .dropdown {
        &.show {
          a.dropdown-toggle {
            &:after {
              margin-top: 0.25rem;
              @include transform(rotate(180deg));
              pointer-events: none;
            }
          }
        }
        .dropdown-menu {
          padding: 1rem;
          left: 1.5rem;
          @include box-shadow(0 1.5rem 3rem #48013726);
          border: 0px;
          .dropdown-item {
            padding: 0.5rem 0.5rem;
            @include transition(0.4s all);
          }
        }
        &.mega-menu {
          .dropdown-menu {
            margin-top: 0px;
            min-width: 45rem;
            left: -19rem;
            right: auto;
            .row {
              .col-lg-6 {
                .logo-part {
                  padding: 0.5rem 0rem;
                  .logo{
                    height: 2.5rem;
                  }
                  .titles {
                    p{
                      &.xs-d-block{
                        display: block;
                      }
                    }
                  }
                }
                &.product-feature {
                  padding-right: 1rem;
                  h4 {
                    display: block;
                  }
                }
                &.platform-feature {
                  padding-left: 1rem;
                  border-left: 1px solid #ddd;
                }
              }
            }
          }
          &.fullscreen-menu {
            position: static;
            .dropdown-menu {
              margin-top: 0px;
              left: 0rem;
              width: 100%;
            }
          }
          &.right {
            .dropdown-menu {
              left: auto;
              right: 0rem;
            }
          }
        }
        &.company-menu {
          .dropdown-menu {
            margin-top: 0px;
            min-width: 14rem;
          }
        }
        &.resource-menu {
          .dropdown-menu {
            margin-top: 0px;
            min-width: 13rem;
          }
        }
        &.dropdown-submenu {
          min-width: 13rem;
          padding-left: 0rem;
          position: relative;
          a.dropdown-toggle {
            &::after {
              margin-top: 2px;
              font-size: 0.85rem;
              @include transform(rotate(0deg));
            }
          }
          &.show {
            a.dropdown-toggle {
              &::after {
                margin-top: 1px;
                font-size: 0.85rem;
                @include transform(rotate(270deg));
              }
            }
          }
          &.dropdown-submenu-hover {
            &:hover {
              a.dropdown-toggle {
                &::after {
                  font-size: 0.85rem;
                  pointer-events: none;
                  @include transform(rotate(270deg));
                }
              }
              > ul.dropdown-menu {
                &.hover-submenu {
                  margin-top: -0.0125rem;
                  display: block;
                }
              }
            }
          }

          .nav-link {
            padding: 0.5rem 0.5rem !important;
          }
          .dropdown-menu {
            min-width: 14rem;
            top: 0;
            left: 100%;
            margin-top: 0rem;
            padding: 1rem;
            border: 0rem;
          }
        }
      }
    }
    &.nav-parent {
      .navbar-nav{
        .nav-link{
          font-size: 1rem;
          padding: 1.85rem 1.125rem;
        }
      } 
      // .allproduct {
      //   display: none;
      // }
      &.nav-top {
        position: relative;
        z-index: 2;
        margin-top: 5.5rem;
        background-color: transparent !important;
        box-shadow: none;
        .navbar-brand {
          &.qkr-biz{
            background-size: 2rem 2rem;
            font-size: 1.75rem;
          }
        }
        .nav-item-hide{
          display: none;
        }
        .nav-item-scroll-show{
          display: none;
        }
      }
      &.nav-scroll {
        .nav-link {
          font-size: 1rem;
        }
      }
      &.nav-scroll-fixed {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
        .navbar-nav{
          .nav-link{
            padding: 1.75rem 1.25rem;
          }
        }
        .nav-item-scroll-show{
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          display: block;
        }
      }
      
      &.fixed-top{
        .nav-item-scroll-show{
          padding-top: 1.5rem;
          display: block;
        }
      }
    }
    &.nav-desktop-hide {
      display: none;
    }
    &.nav-hide {
      display: none;
    }
    .btn-login{
      display: none
    }
    .nav-form{
      display: flex;
      align-items: center;
      .btn {
        width: 6.5rem;
        font-size: 1rem;
      }
    }
    
  } 
}
@media (min-width: 1200px) {
  .navbar-light {
    .navbar-nav {
      .nav-link {
        padding: 2rem 2rem;
        font-size: 1.125rem;
      }
    }
    &.nav-parent {
      &.nav-top {
        margin-top: 6.5rem;
      }
      .navbar-nav{
        .nav-link{
           font-size: 1.063rem;
           padding:1.5rem 1.5rem;
        }
      }
      &.fixed-top{
        .navbar-nav{
          .nav-link{
            font-size: 1.125rem;
            padding: 2rem 1.5rem;
          }
        }
      }
       &.nav-scroll {
        .nav-link {
          font-size: 1.063rem;
        }
      }
      &.nav-scroll-fixed{
        .navbar-nav{
          .nav-link{
            padding: 2rem 1.5rem;
          }
        }
      }  
    }
    &.nav-inner{
      .navbar-brand-width {
          min-width: 10.5rem;
      }
    }
  }        
}
@media (min-width: 1400px) {
  .navbar-light {
    .navbar-brand {
      &.qkr-biz {
        font-size: 2.125rem;
      }
      &.qkr-hr {
        font-size: 1.625rem;
      }
      &.qkr-visit {
        font-size: 1.625rem;
      }
      &.qkr-expense {
        font-size: 1.625rem;
      }
      &.qkr-desk {
        font-size: 1.625rem;
      }
      &.qkr-sales {
        font-size: 1.625rem;
      }
      &.qkr-tenders {
        font-size: 1.625rem;
      }
      &.qkr-task {
        font-size: 1.625rem;
      }
      &.qkr-projects {
        font-size: 1.625rem;
      }
    }
    .navbar-nav {
      .dropdown {
        &.mega-menu {
          &.fullscreen-menu {
            .dropdown-menu {
              min-width: 45rem;
              .titles {
                h5 {
                  font-size: 1rem !important;
                }
                p {
                  font-size: 0.85rem !important;
                }
              }
            }
          }
        }
      }
    }
    &.nav-inner{
      .navbar-brand-width {
          min-width: 15.25rem;
      }
    }
  }
}
@media (min-width: 1900px) {
  .navbar-light {
    .navbar-nav {
      .nav-link {
        padding: 2rem 2.5rem;
        font-size: 1.125rem;
      }
      .dropdown {
        .dropdown-toggle {
          &:after {
            font-size: 1rem;
          }
        }
        &.mega-menu {
          .dropdown-menu {
            .titles {
              h5 {
                font-size: 1.125rem !important;
              }
              p {
                font-size: 0.85rem !important;
              }
            }
          }
          &.fullscreen-menu {
            .dropdown-menu {
              .titles {
                h5 {
                  font-size: 1.125rem !important;
                }
                p {
                  font-size: 0.9rem !important;
                }
              }
            }
          }
        }
      }
    }
    &.nav-parent {
      &.nav-top {
        margin-top: 6.5rem;
      }
      .navbar-nav{
        .nav-link{
           font-size: 1.063rem;
           padding:1.5rem 1.75rem;
        }
      }
      &.fixed-top{
        .navbar-nav{
          .nav-link{
            font-size: 1.125rem;
            padding: 2rem 2rem;
          }
        }
      }
       &.nav-scroll {
        .nav-link {
          font-size: 1.063rem;
        }
      }
      &.nav-scroll-fixed{
        .navbar-nav{
          .nav-link{
            padding: 2rem 2rem;
          }
        }
      }  
    }
    &.nav-inner{
      .navbar-brand-width {
          min-width: 22.75rem;
      }
    }
  }
}
@media (max-width: 991px) {
  .navbar-light {
    &.navbar > .container,
    &.navbar > .container-fluid,
    &.navbar > .container-lg,
    &.navbar > .container-md,
    &.navbar > .container-sm,
    &.navbar > .container-xl,
    &.navbar > .container-xxl,
    &.fixed-top {
      display: flex;
      flex-wrap: inherit;
      align-items: center;
      // justify-content: flex-start;
      justify-content: space-between;
    }
  }
  .navbar-brand {
    font-size: 1.5rem;
    padding-top: 0.125rem;
    &.qkr-hr {
      background-size: 2rem 2.5rem;
      padding-top: 0.25rem;
    }
    &.qkr-visit {
      background-size: 1.75rem 3.25rem;
    }
    &.qkr-expense {
      background-size: 1.75rem 3.25rem;
    }
    &.qkr-desk {
      background-size: 1.75rem 3.25rem;
    }
    &.qkr-sales {
      background-size: 2rem 2.85rem;
    }
    &.qkr-tenders {
      background-size:1.75rem 2.85rem;
    }
    &.qkr-task {
      background-size: 1.75rem 3.25rem;
    }
    &.qkr-projects {
      background-size: 1.75rem 3.25rem;
    }
  }
  .navbar-nav {
    .nav-link {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
    .dropdown {
      .dropdown-toggle {
        width: 100%;
        position: relative;
        &:after {
          position: absolute;
          right:0.75rem;
          @include transform(rotate(0deg));
        }
        &.show {
          &:after {
            @include transform(rotate(180deg));
          }
        }
      }
      &.show {
        .dropdown-toggle {
          &:after {
            @include transform(rotate(180deg));
          }
        }
      }
      .dropdown-menu {
        margin: 0rem;
        padding: 0rem;
        border: unset;
        .dropdown-item {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          font-size: 0.9rem;
          &.active, &:hover, &:active, &:focus {
            color: $text-magenta;
          }
        }
        .container {
          padding-left: 0rem;
          padding-right: 0rem;
          .dropdown-item {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
        }
        .dropdown-submenu {
          padding-left: 1rem;
          a.dropdown-toggle {
            &::after {
              @include transform(rotate(0deg) !important);
            }
          }
          &.show {
            a.dropdown-toggle {
              &::after {
                @include transform(rotate(180deg) !important);
              }
            }
          }
        }
      }
    }
   
  }
  .navbar-light,
  .offcanvas  {
    .nav-form{
         margin-top: 1.5rem;
    }
  }
  .navbar-light {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    &.nav-inner{
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1040;
    }
    &.nav-scroll {
      position: relative;
      z-index: 1029;
      margin-top: 4.5rem;
      padding-top: 0rem;
      padding-bottom: 0rem;
      box-shadow: none;
      .container {
        // .navbar-brand {
        //   padding-top: 0rem;
        //   padding-bottom: 0rem;
        // }
        .navbar-nav {
          .nav-link {
            padding: 0.75rem 0.75rem;
            white-space: nowrap;
          }
        }
        .ms-auto {
          margin-left: unset !important;
          margin-right: auto !important;
        }
      }
    }
    &.nav-desktop {
      display: none;
    }

  }
}
@media (max-width: 767px) {
  .navbar-light {
    &.nav-scroll {
      .container {
        flex-wrap: wrap;
        .navbar-nav{
          background-color: $text-magenta-extralight !important;
          margin-left: -0.75rem !important;
          margin-right: -0.75rem !important;
          padding: 0px 0.5rem;
          @include box-shadow(0px 0px 2px rgba(150, 0, 107, 0.1)inset) ;
        }
        
      }
    }
  }
}
@media (max-width: 575px) {
}
@media (max-width: 480px) {
}
