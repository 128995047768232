// clients
.clients {
    padding: 3rem 0rem;
    background-color: $bg-gray;
    h2 {
      font-family: "Poppins SemiBold";
    }
    .slider {
      width: 90%;
      height: auto;
      margin: 0rem auto;
      .slick-slide {
        padding: 0rem 0.25rem;
        @include transition( all ease-in-out 0.3s);
        img {
          width: 120px;
          height: 120px;
          margin: 0 auto;
        }
      }
      .slick-arrow {
        width: 2rem;
        height: 2rem;
      }
      .slick-prev:before,
      .slick-next:before {
        font-family: "Font Awesome 6 Free";
        font-size: 1.5rem;
        font-weight: 600;
        color: $text-magenta;
        line-height: 1;
        opacity: 0.75;
      }
      .slick-prev:before {
        content: "\f104";
      }
      .slick-next:before {
        content: "\f105";
      }
    }
    &.client-details {
      background-color: $bg-white;
      .slick-slide {
        padding: 0rem 1rem;
        margin: 0.5rem 0rem;
        .card {
          border: 1px solid #f0f0f0;
          @include border-radius(1rem);
          @include transition(all 0.3s);
          .card-body {
            position: relative;
            padding: 1.5rem;
            padding-top: 5rem;
            &::before {
              content: "";
              position: absolute;
              width: 2.25rem;
              height: 2.25rem;
              top: 1.625rem;
              right: auto;
              left: auto;
              background: url(/assets/images/ClientComma.svg);
              background-repeat: no-repeat;
              background-size: 100%;
            }
            h4 {
              font-size: 1.5rem;
              margin-bottom: 0.75rem;
              margin-top: 1.25rem;
            }
          }
          &:hover {
            @include box-shadow(0px 2px 10px rgba(120, 120, 120, 0.15));
          }
        }
      }
    }
}
.heading-mid-top{
  justify-content: center;
  background-color: $bg-white;
  padding:1.25rem 1.5rem;
  border-radius: 1.5rem;
  @include box-shadow(0px 3px 15px rgba(0, 0, 0, 0.0901960784));
  .list-group-item{
    padding: 0rem 1rem;
    background-color: unset;
    border-bottom: none;
    border-top: none;
    .text-color{
      color: #3F7DFB;
    }
    &:first-child{
      border-left: none;        
    }
    &:last-child{
      border-right: none;
    }
  }
  &.heading-mid-bottom{
    padding:1rem 1.5rem;
    margin-bottom: -5.5rem;
    box-shadow: 0px 8px 20px #3F7DFB29;
  }
}
.logo-scroll {
  position: relative;
  margin-top: -2.5rem;
  .slick-slider{
    width: 100%;
    height: auto;
    padding-top: 1rem;
    margin: 0rem auto;
    .slick-slide {
        padding:2rem 0.25rem;
        text-align: center !important;
        @include transition( all ease-in-out 0.3s);
        img {
          width: 120px;
          height: 120px;
          margin: 0 auto;
          filter: brightness(0) saturate(100%) invert(61%) sepia(1%) saturate(229%) hue-rotate(104deg) brightness(96%) contrast(93%);
          &:hover{
            filter: none;
          }
        }
      }
    }
    .slick-arrow {
      width: 2rem;
      height: 2rem;
    }
    .slick-prev:before,
    .slick-next:before {
      font-family: "Font Awesome 6 Free";
      font-size: 1.5rem;
      font-weight: 600;
      color: $text-magenta;
      line-height: 1;
      opacity: 0.75;
    }
    .slick-prev:before {
      content: "\f104";
    }
    .slick-next:before {
      content: "\f105";
    }
}
//
.slickSlider {
  .slick-initialized {
      width: 90%;
      margin: 0rem auto;
      .slick-slide {
      padding: 1.5rem 1rem;
      }
  }
  .slick-arrow {
      width: 2rem;
      height: 2rem;
  }
  .slick-prev:before,
  .slick-next:before {
      font-family: "Font Awesome 6 Free";
      font-size: 1.5rem;
      font-weight: 600;
      color: $text-magenta;
      line-height: 1;
      opacity: 0.75;
  }
  .slick-prev:before {
      content: "\f104";
  }
  .slick-next:before {
      content: "\f105";
  }
}
.slider-100{
  .slick-initialized {
      width: 100%;
      margin-left: -.5rem;
      margin-right: -.5rem;
      .slick-slide {
      padding: 1.5rem 1rem;
      }
  }
}
.contentSlider {
  padding: 2.5rem 0rem;
  background-color: $bg-gray;
  .slick-slide {
      opacity: 0.5;
      .silck_item {
      background-color: $bg-white;
      padding: 1rem !important;
      @include border-radius(0.5rem);
      .silck_hidden {
          display: none;
      }
      h5 {
          font-size: 1.25rem;
          text-decoration: underline;
          font-family: "Poppins SemiBold";
      }
      h4 {
          font-size: 1rem;
          font-family: "Poppins SemiBold";
      }
      p {
          font-size: 0.85rem;
          margin-bottom: 1rem;
      }
      img {
          width: 100%;
      }
      }
      &.slick-current {
      opacity: 1;
      .silck_item {
          @include box-shadow(-1px 8px 22px rgba(0, 0, 0, 0.1607843137));
          @include transition(all 0.6s ease);
          .silck_hidden {
          display: block;
          }
          h5 {
          color: $text-magenta;
          }
          h4 {
          color: $text-magenta;
          }
      }
      }
  }
  .slick-prev,
  .slick-next {
      top: 38%;
  }
}
.ClientsSay {
    position: relative;
    padding: 5rem 0rem 4rem 0rem;
    background-color: $text-violet-light;
    .slick-slider {
      padding-top: 1rem;
      .slick-dots {
        top: -1.5rem;
        left: 0;
        width: 100%;
        height: 2rem;
        text-align: center;
        padding-left: 0%;
        li {
          margin: 0 2px;
          button {
            width: 0.2rem;
            height: 0.2rem;
            padding: 0.2rem;
            background-color: #000;
            @include border-radius(50%);
            &:before {
              content: none;
            }
          }
          &.slick-active {
            button {
              width: 0.5rem;
              height: 0.5rem;
              padding: 0.5rem;
              background-color: $text-violet;
            }
          }
        }
      }
    }
    .ClientsSayInner {
      padding: 0rem;
      .clint-img {
        position: relative;
        width: 16.125rem;
        height: 16rem;
        margin: 0 auto;
        margin-bottom: 3rem;
        background-color: $text-violet;
        @include border-radius(50%);
        .img-fluid {
          width: 15.5rem;
          height: 15.438rem;
          @include border-radius(50%);
        }
        &::after {
          content: "";
          bottom: -2rem;
          right: 0rem;
          width: 6.688rem;
          height: 1.563rem;
          background: url(/assets/images/clientdotted-bottom.png);
          position: absolute;
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
      .quote-right {
        font-size: 1.25rem;
        font-family: "Poppins Medium";
        padding: 3rem 0rem;
        margin-bottom: 1rem;
        &.left {
          position: relative;
          &:before {
            top: 0;
            content: "";
            background: url(/assets/images/clientQuote.svg);
            position: absolute;
            background-repeat: no-repeat;
            background-size: 95%;
            width: 3rem;
            height: 3rem;
          }
        }
        &.right {
          position: relative;
          &:after {
            content: "";
            background: url(/assets/images/clientQuote.svg);
            position: absolute;
            background-repeat: no-repeat;
            background-size: 95%;
            bottom: 0rem;
            width: 3rem;
            height: 3rem;
            @include transform(rotate(180deg));
          }
        }
      }
      .text-title {
        font-size: 1.75rem;
        font-family: "Poppins SemiBold";
        margin-bottom: 0.125rem;
      }
      .text-violet {
        font-size: 1.125rem;
        color: $text-violet-dark;
        font-family: "Poppins Medium";
        margin-top: 0.5rem;
        margin-bottom: 0rem;
      }
    }
}
// content Silder
.content-Silder {
    padding: 2.5rem 0rem;
    background-color: $bg-gray;
    .slick-slide {
        margin: 1.5rem 1rem;
        @include border-radius(0.5rem);
        h5 {
        font-size: 1.25rem;
        text-decoration: underline;
        }
        h4 {
        font-size: 1rem;
        }
        p {
        font-size: 0.85rem;
        }
        img {
        width: 100%;
        }
        &.slick-active {
        background-color: $bg-white;
        opacity: 0.7;
        .silck_hidden {
            margin-bottom: 3.5rem;
        }
        }
        &.slick-current {
        opacity: 1;
        background-color: $bg-white;
        @include box-shadow(-1px 8px 22px rgba(0, 0, 0, 0.1607843137));
        .silck_hidden {
            margin-bottom: 3.5rem;
        }
        h5 {
            color: $text-magenta;
        }
        h4 {
            color: $text-magenta;
        }
        }
    }
}
.hrms-tab-content {
    background-color: $bg-white;
    width: 100%;
    height: auto;
    padding: 2.5rem 0;
    margin-bottom: 1.5rem;
    border: unset;
    @include box-shadow(0px 0px 30px #00000014);
    .tabs-left {
      margin-bottom: 0rem;
      h3 {
        font-family: "Poppins SemiBold";
      }
      .card {
        @include border-radius(0rem);
        border: unset !important;
        @include transition(all 0.4s);
        .card-header {
          font-size: 1rem;
          font-family: "Poppins Medium";
          background-color: transparent;
          position: relative;
          cursor: pointer;
          padding: 0.5rem 0rem;
          padding-right: 1rem;
          padding-left: 1.5rem;
          border: unset !important;
          @include border-radius(0rem);
          &:before {
            content: "";
            position: absolute;
            height: 20px;
            left: 0;
            top: 0.625rem;
            width: 20px;
            background: url(/assets/images/kiteIcon.svg) no-repeat;
            background-size: 50% 100%;
          }
          &:after {
            visibility: hidden;
            padding-left: 1rem;
            content: "\f178";
            font-size: 1rem;
            font-weight: 600;
            position: absolute;
            font-family: "Font Awesome 6 Free";
            vertical-align: baseline;
          }
        }
        &.active {
          color: $text-magenta;
          .card-header {
            color: $text-magenta;
            &:after {
              visibility: hidden;
            }
          }
        }
        &:hover {
          @include transition(all 0.4s);
          .card-header {
            color: $text-magenta;
            &:after {
              visibility: hidden;
            }
          }
        }
      }
    }
    .tab-content {
      width: 100%;
      height: auto;
      padding: 1rem 0rem;
      .tab-pane {
        .imgSize {
          max-width: 100%;
          height: auto;
        }
      }
    }
}
.multitab_content {
  &.nav-tabs {
    border-bottom: unset;
    .tabscroll-view {
      margin-top: 1rem;
      margin-bottom: 4.5rem;
      .tabs-left {
        margin-bottom: 0.5rem;
        padding-bottom: 2px;
        .slick-list {
          padding-top: 1rem !important;
        }
        .slick-slide {
          @include transition(all 0.4s);
          .topdiv {
            cursor: pointer;
            margin-bottom: 1rem;
            @include transition(all 0.7s);
            .icons {
              width: 3.5rem;
              height: 3.5rem;
              margin: 0 auto;
              text-align: center;
              margin-bottom: 0.25rem;
              background-color: #f7f7fc;
              @include border-radius(0.75rem);
              .img {
                width: 2.5rem;
                height: 2.5rem;
                margin-top: 0.85rem;
                display: inline-block;
              }
            }
            .text {
              color: #9797bd;
              font-size: 1rem;
              text-align: center;
              font-family: "Poppins Medium";
            }
          }
          &.slick-current {
            .icons {
              background: linear-gradient(130deg, #24223e, #282642);
              @include transform(scale(1));
              .img {
                width: 2.5rem;
                height: 2.5rem;
                display: inline-block;
              }
            }
            .text {
              color: #24223e;
              font-size: 1.125rem;
              font-family: "Poppins SemiBold";
            }
          }
          &:hover {
            @include transform (translateY(-3px));
          }
        }
      }
      .tab-content {
        padding: 0rem;
        border-top: 1px solid $text-magenta;
        border-left: 1px solid $text-magenta;
        border-right: 1px solid $text-pink;
        border-bottom: 1px solid $text-pink;
        @include border-radius(1rem);
        @include box-shadow(0px 2px 90px rgba(150, 0, 107, 0.15));
        .tab-panel {
          display: flex !important;
          .img-fluid {
            @include border-radius(1rem);
            display: inline;
          }
        }
      }
    }
    &.abouts {
      width: 100%;
      height: auto;
      padding: 2.5rem 0;
      margin-bottom: 0;
      border-bottom: unset;
      .tabs-left {
        margin-bottom: 0rem;
        .card {
          background-color: $bg-black;
          margin-bottom: 1.5rem;
          padding: 0.5rem 0rem;
          @include border-radius(0rem);
          @include transition(all 0.7s);
          .card-header {
            font-family: "Poppins Medium";
            background-color: transparent;
            // padding-right: 3rem;
            position: relative;
            cursor: pointer;
            padding: 0.75rem 1rem;
            color: $text-white;
            font-size: 1.75rem;
            text-align: right;
            transition-timing-function: ease-in;
            @include transition(0.2s);
            border: none;
            @include border-radius(0rem);
          }
          &.cardActive {
            background-color: $text-magenta;
            @include transition(all 0.7s);
            .card-header {
              padding-right: 3rem;
              @include transition(0.25s);
              transition-timing-function: ease-out;
              &::before {
                content: "\f178";
                font-size: 1.25rem;
                font-weight: 600;
                right: 1rem;
                top: 1.25rem;
                position: absolute;
                font-family: "Font Awesome 6 Free";
                vertical-align: baseline;
              }
            }
          }
          &:hover {
            background-color: $text-magenta;
            .card-header {
              padding-right: 3rem;
              @include transition(0.25s);
              transition-timing-function: ease-out;
              &::before {
                content: "\f178";
                font-size: 1.25rem;
                font-weight: 600;
                right: 1rem;
                top: 1.25rem;
                position: absolute;
                font-family: "Font Awesome 6 Free";
                vertical-align: baseline;
              }
            }
          }
        }
      }
      .tab-content {
        text-align: left;
        font-size: 1rem;
        .tab-pane {
          padding: 1.5rem;
          h2 {
            font-family: "Poppins SemiBold";
            font-size: 2.25rem;
            margin-bottom: 1.75rem;
          }
        }
      }
    }
    .tab-scroll {
      padding: 1rem;
      @include border-radius(2rem);
      h1 {
        padding: 3rem 0rem;
        padding-bottom: 0rem;
      }
      .tabs-left {
        background-color: $text-white;
        padding: 1.5rem 3rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        @include border-radius(3rem);
        @include box-shadow(unset);
        // @include box-shadow(0px 0px 20px #0000001a);
        .slick-slider {
          .slick-initialized {
            width: 90%;
            margin: 0rem auto;
            .slick-slide {
              padding: 1.5rem 1rem;
            }
          }
          .slick-arrow {
            width: 2rem;
            height: 2rem;
          }
          .slick-prev:before,
          .slick-next:before {
            font-family: "Font Awesome 6 Free";
            font-size: 1.5rem;
            font-weight: 600;
            color: $text-magenta;
            line-height: 1;
            opacity: 0.75;
          }
          .slick-prev:before {
            content: "\f104";
          }
          .slick-next:before {
            content: "\f105";
          }
        }
        .slick-list {
          .slick-track {
            display: flex;
            justify-content: space-around;
          }
        }
        .slick-slide {
          @include transition(all 0.4s);
          .topdiv {
            cursor: pointer;
            display: flex;
            justify-content: center;
            @include transition(all 0.7s);
            .icons {
              width: 3.5rem;
              height: 3.5rem;
              margin-right: 1rem;
              text-align: center;
              background-color: $text-deepsky-extralight;
              // background-color: $text-violet-light;
              @include border-radius(0.75rem);
              .img {
                width: 2.5rem;
                height: 2.5rem;
                margin-top: 0.85rem;
                display: inline-block;
              }
            }
            .text {
              font-family: "Poppins Medium";
              color: $text-black;
              font-size: 1.125rem;
              text-align: center;
            }
          }
          &.slick-current {
            .icons {
              // background-color: $text-violet-light;
              background-color: $text-deepsky-extralight;
              .img {
                width: 2.5rem;
                height: 2.5rem;
                display: inline-block;
              }
            }
            .text {
              // color:$text-magenta;
              color: $text-deepsky;
            }
          }
        }
      }
      .tab-content {
        padding: 1.5rem;
        .tab-panel {
          h3 {
            font-family: "Poppins SemiBold";
          }
          .img-fluid {
            margin-top: 0.5rem;
            // @include border-radius(1rem);
          }
        }
      }
      &.team-scroll{
         padding: 3rem 3rem 0;
        .tab-content {
          padding: 0rem;
          .tab-panel {
            .img-fluid {
              display: inline;
              border-top: 0.5rem solid $text-deepsky-light-dark;
              border-right: 0.5rem solid $text-deepsky-light-dark;
              border-left: 0.5rem solid $text-deepsky-light-dark;
              @include border-top-left-radius(1rem);
              @include border-top-right-radius(1rem);
              @include border-bottom-right-radius(0rem);
              @include border-bottom-left-radius(0rem);
            }
          }
        }
      }
      &.bg-pink {
        background-color: $text-pink-light;
        .tabs-left {
          @include box-shadow(unset);
          .slick-slide {
            .topdiv {
              .icons {
                background-color: $text-violet-light;
              }
            }
            &.slick-current {
              .icons {
                background-color: $text-violet-light;
              }
              .text {
                color: $text-magenta;
              }
            }
          }
        }
      }
      &.bg-blue-magenta-light {
        background-color: $text-slateblue-light;
        .tabs-left {
          @include box-shadow(unset);
          .slick-slide {
            .topdiv {
              .icons {
                background-color: $text-slateblue-light;
              }
            }
            &.slick-current {
              .icons {
                background-color: $text-slateblue-light;
              }
              .text {
                color: $text-deepsky;
              }
            }
          }
        }
      }
      &.tabscroll-warning {
        background-color: transparent;
        .tabs-left {
          .slick-slide {
            .topdiv {
              .icons {
                background-color: $text-warning-light;
              }
            }
            &.slick-current {
              .icons {
                background-color: $text-warning-light;
              }
              .text {
                color: $text-warning-dark;
              }
            }
          }
        }
      }
    }
    .tabscroll-hr {
      margin-top: 1rem;
      margin-bottom: 2.5rem;
      .tabs-left {
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        .slick-list {
          padding-top: 1rem !important;
        }
        .slick-slide {
          @include transition(all 0.4s);
          .topdiv {
            cursor: pointer;
            min-height: 6rem;
            margin-bottom: 0.5rem;
            @include transition(all 0.7s);
            .icons {
              width: 3.5rem;
              height: 3.5rem;
              margin: 0 auto;
              margin-bottom: 0.5rem;
              text-align: center;
              background-color: $bg-gray-extralight;
              @include border-radius(0.5rem);
              // @include border-radius(50%);
              @include transition(all 0.3s linear);
              .img {
                width: 2.5rem;
                height: 2.5rem;
                margin-top: 0.85rem;
                display: inline-block;
              }
            }
            .text {
              color: $text-dark-gray;
              font-size: 1rem;
              text-align: center;
              font-family: "Poppins Medium";
            }
          }
          &.slick-current {
            .icons {
              width: 3.5rem;
              height: 3.5rem;
              background-color: $text-black;
              // background: linear-gradient(
              //   45deg,
              //   $text-magenta,
              //   $text-pink-light-extra-dark
              // );
              // @include border-radius(30px 30px 0 30px);
              .img {
                width: 2.5rem;
                height: 2.5rem;
                display: inline-block;
              }
            }
            .text {
              color:$text-black;
              // color: $text-magenta;
              font-size: 1rem;
              font-family: "Poppins SemiBold";
            }
          }
        }
      }
      .tab-content {
        padding: 1rem;
        border: 1px solid #ddd;
        @include border-radius(1rem);
        .tab-panel{
          display: flex !important;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.team-slider {
    padding: 2.5rem 0rem;
    background-color: $bg-gray;
    .slick-slide {
      .team-body {
        padding: 0.25rem !important;
        position: relative;
        opacity: unset !important;
        overflow: hidden;
        @include border-radius(0.5rem);
        .images-body {
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            height: auto;
            opacity: 1;
          }
          .content-body {
            background: transparent
              linear-gradient(90deg, $text-pink 0%, $text-magenta 100%) 0% 0%
              no-repeat;
            opacity: 0.8;
            text-align: center;
            width: 100%;
            padding: 1rem;
            display: block;
            position: absolute;
            left: 0;
            bottom: -100%;
            @include border-bottom-left-radius(0.5rem);
            @include border-bottom-right-radius(0.5rem);
            @include transition(all 0.3s);
            h6 {
              font-family: "Poppins Regular";
              font-size: 1.25rem;
              margin-bottom: 0.25rem;
              text-decoration: none;
            }
            p {
              font-size: 0.75rem;
            }
            .list-unstyled {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              li {
                background-color: $bg-white;
                font-size: 1rem;
                line-height: 35px;
                text-align: center;
                height: 35px;
                width: 35px;
                margin: 0rem 0.5rem;
                @include border-radius(50%);
                @include transition(all 0.3s ease 0s);
                a {
                  color: $text-magenta;
                }
              }
            }
          }
        }
  
        &:hover {
          .images-body {
            .content-body {
              bottom: 0;
            }
          }
        }
      }
      &.slick-current {
        .team-body {
          @include box-shadow(-1px 5px 17px rgba(0, 0, 0, 0.1607843137));
        }
      }
    }
}
.slick-slider-dots {
  .slick-dots {
    li {
      button {
        &:before {
          content: "" !important;
          width: 0.85rem;
          height: 0.85rem;
          @include border-radius(50%);
          line-height: 85rem;
          background-color: transparent;
          opacity: 1;
          border: 1px solid $text-magenta;
        }
      }
      &.slick-active {
        button {
          &:before {
            background-color: $text-magenta;
            border: 1px solid $text-magenta;
          }
        }
      }
    }
  }
}
.hrms-blogs-slider {
    padding:3rem 0rem;
    .card {
      min-height: 400px;
      border: unset;
      @include box-shadow(0px 3px 15px #00000017);
      @include border-radius(0.5rem);
      transition: all 500ms ease;
      &:hover{
        transform: translateY(-5px);
        $box-shadow:(0px 3px 15px #00000017);
      }
      .card-body {
        padding: 0rem;
        background-color: $bg-white;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        .img-fluid{
          border-radius: 0.5rem !important;
        }
      }
      .card-footer {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        background-color:$bg-white;
        border-top: unset;
        border-radius: 0.5rem;
        h3{
          font-size: 1.125rem;
          font-family: "Poppins SemiBold";
          margin-bottom: 1rem
        }
        .fn-user{
          font-size: 1rem;
        }
        
        .img-user{
          width: 2rem;
          height: 2rem;
          display: inline;
          display: none;
          margin-right: 0.5rem;
          border-radius: 50%;
        }
      }

    }
}
.custom-progress-bar{
  .progress-bar{
    background-color: $text-alice-blue-dark;
  }
}
.scrolltop-fixed {
  position: sticky;
  top: 6rem; 
  z-index: 1000;
  height: auto;
  // max-height: calc(100vh - 9rem);
  // overflow-y: auto;
}

.table-content{
  display: none;
  background-color:$text-alice-blue-light;
  padding: 2rem 2rem;
  border-radius: 1.5rem;
  .nav-item{
    .nav-link{
      cursor: pointer;
      padding:0.5rem 0;
      padding-left: 1rem;
    }
    &.active{
      .nav-link {
       color:$text-alice-blue;

       }
    }
  }
  .time-line {
    .nav-item {
      position: relative;
      padding-left: 1rem;
      &::before {
        content: "";
        position: absolute;
        height: calc(100%);
        width: 2px;
        left: 2px;
        top: 1rem;
      }
  
      &::after {
        content: "";
        top: 4px;
        left: 0;
        position: absolute;
        height: 6px;
        width: 6px;
        margin-top: 0.75rem;
        border-radius: 50%;
      }
  
      &.line-gray {
        &::before {
          background-color: $text-gray;
        }
        &::after {
          background-color: $text-gray;
        }
        // &:first-child {
        //   &::before {
        //     top: 50%; 
        //     //background-color: $text-gray;
        //   }
        // }
          &:last-child {
          &::before {
            top: 0; 
            background-color: transparent; 
          }
        }
      }
  
      &.alice-blue {
        &::before {
          background-color: $text-alice-blue-dark;
        }
        &::after {
          background-color: $text-alice-blue-dark;
        }
        // &:first-child {
        //   &::before {
        //     top: 50%; 
        //     background-color: transparent;
        //   }
        // }
          &:last-child {
          &::before {
            top: 0; 
            background-color: transparent; 
          }
        }
      }
  
      &.active {
        color: $text-magenta;
      }
    }
  }
}
.blog-free-trial{
  padding: 2rem 2rem;
  margin-bottom: 2.5rem;
  background-color:$text-blue-magenta-light;
  border-radius: 1.5rem;
  position: relative;
  // &::after{
  //    content: '';
  //     position: absolute;
  //     background-image: url('../../images/hrms/blogs/blog-book-free.png'); 
  //     background-repeat: no-repeat;
  //     display: inline-block;
  //     bottom: 0;
  //     right: 0;
  //     background-size: 13.25rem auto !important;
  //     height: 16rem;
  //     width: 16rem;
  // }
}
.card-supercharg{
  border: unset;
  min-height: auto !important;
  .card-body{
    padding: 1.75rem 1.75rem 0rem 1.75rem !important;
    border-radius: 0.5rem;
    min-height: auto;
  }
} 
.blogs-top{
  margin-top: 6rem;
  padding-bottom: 3rem;
  &.blogs-top-bg{
    .bh-1{
      font-size: 2.75rem;
    }
  }
}

.pricing-top {
  padding:3rem 0rem;
  margin-top: 2rem;
}
@media (min-width:768px) {
  .card-supercharg{
    .card-body{
      position: relative;
      min-height: 31.25rem !important;
        &::after{
          content: '';
          position: absolute;
          background-image: url('../../images/hrms/supercharge.png'); 
          background-repeat: no-repeat;
          display: inline-block;
          bottom: 0;
          left: 1rem;
          width: 100%;
          height: 18rem;
          background-size: 38%;
          
      }
    }
  }
}
@media (min-width: 992px) {
     .clients {
        &.client-details {
          .slick-slide {
            .card {
              .card-body {
                .max-height {
                  min-height: 10rem;
                }
              }
            }
          }
        }
      }
      .hrms-tab-content {
          padding: 0rem;
          .tab-content {
            padding: 3.25rem 0rem;
            &.content-bg {
              background: url(/assets/images/hrms/HrmsBg.png);
              background-repeat: no-repeat;
              background-size: 99%, 100%;
              .tab-pane {
                text-align: center;
                .imgSize {
                  max-width: 100%;
                  height: 363px;
                }
              }
            }
          }
      }
      .multitab_content {
        &.nav-tabs {
          .tab-scroll {
            padding: 1.5rem;
            .tabs-left {
              margin: 1.5rem 0rem;
              padding: 1.5rem 2rem;
            }
          }
        }
      }
      .ClientsSay {
        .slick-slider {
          padding-top: 2rem;
          .slick-dots {
            padding-left: 14%;
            text-align: left;
          }
        }
        .ClientsSayInner {
          .clint-img {
            width: 15.125rem;
            height: 15rem;
            .img-fluid {
              width: 14.5rem;
              height: 14.438rem;
            }
          }
          .quote-right {
            font-size: 1.5rem;
          }
        }
      }
      .pricing-top{
        margin-top: 3.5rem;
      }
      .blogs-top{
          &.blogs-top-bg{
            display: flex;
            flex-wrap: wrap;
            align-items: center !important;
            background: url(../../images/hrms/blogs/blog-top-bg.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            height: 100vh;
            position: relative;
            z-index: 1;
          }
        // margin-top: 3.5rem;
        // background-image: url('../../images/hrms/blogs/blog-top-bg.png'); 
        // background-repeat: no-repeat;
        // background-size: 100% !important;
        // height: 30rem;
      }
      .card-supercharg{
        .card-body{
          min-height: 31.25rem !important;
            &::after{
              background-size: 85%;
              height: 18rem;
          }
        }
      }
      .table-content{
        display: block;
      }
      .heading-mid-top{
        border-radius: 2.5rem;
        .list-group-item{
          padding: 0rem 2rem;
        }
        &.heading-mid-bottom{
          padding: 1.25rem 1.5rem;
          border-radius: 2.85rem;
        }
      }
}
@media (min-width: 1200px) {
       .hrms-tab-content {
          .tab-content {
            padding: 4rem 0rem;
            &.content-bg {
              background-size: 100%, 100%;
              .tab-pane {
                .imgSize {
                  height: 438px;
                }
              }
            }
          }
        }
        .pricing-top{
          margin-top: 5rem;
        }
        .blogs-top{
          margin-top: 6rem;
        }
        .card-supercharg{
          .card-body{
            min-height: 32rem !important;
              &::after{
                background-size: 87%;
            }
          }
        }
        .blog-free-trial{
          padding-bottom: 8rem ;
            &::after{
              content: '';
              position: absolute;
              background-image: url('../../images/hrms/blogs/blog-book-free.png'); 
              background-repeat: no-repeat;
              display: inline-block;
              bottom: 0;
              right: 0;
              background-size: 13rem auto !important;
              height: 15rem;
              width: 14rem;
          }
        }
}
@media (min-width: 1400px) {
      .row-space-1_5{
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        &>*{
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }
      .hrms-tab-content {
          .tab-content {
            &.content-bg {
              background-size: 97.5%, 100%;
              .tab-pane {
                .imgSize {
                  height: 491px;
                }
              }
            }
          }
      } 
      .ClientsSay {
        z-index: 11;
        &:before {
          z-index: 12;
          content: "";
          top: 10.5rem;
          left: -2.5rem;
          width: 425px;
          height: 105px;
          background: url(/assets/images/clientdotted-left.png);
          position: absolute;
          background-repeat: no-repeat;
          background-size: 55%;
        }
        .ClientsSayInner {
          .clint-img {
            width: 17.125rem;
            height: 17rem;
            .img-fluid {
              width: 16.5rem;
              height: 16.438rem;
            }
          }
          .left-border {
            position: relative;
            width: 15rem;
            &:before {
              content: "";
              position: absolute;
              top: 51%;
              left: 0;
              width: 80%;
              height: 0.25rem;
              display: inherit;
              background-color: $text-violet-dark;
              opacity: 0.6;
            }
          }
        }
      }
      .blog-free-trial{
        padding-bottom: 8rem ;
          &::after{
            content: '';
            position: absolute;
            background-image: url('../../images/hrms/blogs/blog-book-free.png'); 
            background-repeat: no-repeat;
            display: inline-block;
            bottom: 0;
            right: 0;
            background-size: 13.25rem auto !important;
            height: 16rem;
            width: 16rem;
        }
      }
      .card-supercharg{
        .card-body{
           min-height: 33.25rem !important;
            &::after{
              background-size: 70%;
          }
        }
      }

}
@media (min-width: 1600px) {
    .ClientsSay {
      &:before {
        background-size: 70%;
      }
    }
    .card-supercharg{
      .card-body{
         min-height: 33.25rem !important;
          &::after{
            background-size: 65%;
        }
      }
    }
  }
@media (min-width: 1900px) {
     .contentSlider {
        .slick-slide {
          .silck_item {
            padding: 1.75rem 1.5rem !important;
            h5 {
              font-size: 1.75rem;
            }
            h4 {
              font-size: 1.25rem;
            }
            p {
              font-size: 1.125rem;
            }
          }
        }
      }
      .hrms-tab-content {
        .tabs-left {
            h3 {
              font-size: 2rem;
              margin-bottom: 2rem;
            }
            .card {
              .card-header {
                font-size: 1.45rem;
                padding: 0.75rem 0.75rem 0.75rem 2rem;
                &:before {
                  top: 1.25rem;
                  background-size: 70% 100%;
                }
              }
            }
        }
        .tab-content {
          &.content-bg {
            background-size: 93.5%, 100%;
            .tab-pane {
              .vedio-player {
                margin-left: 0%;
              }
              .imgSize {
                height: 598px;
              }
            }
          }
        }
      }  
      .card-supercharg{
        .card-body{
           min-height: 35.5rem !important;
            &::after{
              background-size: 65%;
          }
        }
      } 
}
@media (max-width: 991px) {   
      .hrms-tab-content {
        .tabs-left {
          .card {
            display: inline-block;
          }
        }
      }
     .ClientsSay {
        &:before {
          background-size: 68%;
        }
        .ClientsSayInner {
          .clint-img {
            width: 20.125rem;
            height: 20rem;
            .img-fluid {
              width: 19.5rem;
              height: 19.438rem;
            }
          }
        }
      }
     
}