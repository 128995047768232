
.btn {
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    font-size: 1rem;
    @include border-radius(0.325rem);
  }
  .btn:focus,
  .btn:active,
  button:focus,
  button:active {
    outline: none !important;
    box-shadow: none !important;
  }
  .btn-magenta {
    color: $text-white;
    background-color: $text-magenta !important;
    border-color: $text-magenta !important;
  }
  .btn-magenta:hover,
  .btn-magenta:focus,
  .btn-magenta:active {
    color: $text-white !important;
    background-color: $text-magenta-dark !important;
    border-color: $text-magenta-dark !important;
  }
  .btn-pink {
    color: $text-white;
    background-color: $text-pink !important;
    border-color: $text-pink !important; 
  }
  .btn-pink:hover,
  .btn-pink:focus,
  .btn-pink:active {
    color: $text-white !important;
    background-color: $text-pink-dark !important;
    border-color: $text-pink-dark !important;
  }
  .btn-outline-magenta {
    color: $text-magenta;
    background-color: $bg-white !important;
    border-color: $text-magenta !important;
  }
  .btn-outline-magenta:hover,
  .btn-outline-magenta:focus,
  .btn-outline-magenta:active {
    color: $text-white !important;
    background-color: $text-magenta-dark !important;
    border-color: $text-magenta-dark !important;
  }
  .magenta-border {
    color: $text-magenta;
    background-color: $bg-white !important;
    border-color: $text-magenta !important;
  }
  .btn-success {
    color: $text-white;
    background-color: $text-success !important;
    border-color: $text-success !important;
  }
  .btn-success:hover,
  .btn-success:focus,
  .btn-success:active {
    color: $text-white !important;
    background-color: $text-success-dark !important;
    border-color: $text-success-dark !important;
  }
  .btn-outline-success {
    color: $text-success-dark;
    background-color: $bg-white !important;
    border-color: $text-success-dark !important;
  }
  .btn-outline-success:hover,
  .btn-outline-success:focus,
  .btn-outline-success:active {
    color: $text-white !important;
    background-color: $text-success-dark !important;
    border-color: $text-success-dark !important;
  }
  @media (min-width: 768px) {

  }
  @media (min-width: 992px) {

  }
  @media (min-width: 1200px) {
    
  }
  @media (min-width: 1400px) {
    .btn {
      padding: 0.625rem 1.125rem;
      font-size: 1rem;
    }
  }
  @media (min-width: 1900px) {
    
  }