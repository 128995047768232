
// #ECF6FF #ECF6FF
$text-deepsky-extralight:#F2FBFF;
$text-deepsky-light:#C1E7F5;
$text-deepsky-light-dark:#90B7DB;
$text-deepsky :#2E6D99;
$text-deepsky-dark:#365F82;

$text-success-light:#e5fef7;
$text-success-light-dark:#b1fae6;
$text-success:#00BD82;
$text-success-dark:#049365;
// #F0F0FF

$text-alice-blue-light:#EBF5FF;
$text-alice-blue:#3366CC;
$text-alice-blue-dark:#32587A;

$text-primary-extralight:#F1F6FF;
$text-primary-light: #d1dffd;
$text-primary-light-dark:#bfd3ff;
$text-primary:#103dbe;
$text-primary-dark:#082f9b;

$text-blue-magenta-extralight:#F7F8FC;
$text-blue-magenta-light:#F0F0FF;
$text-blue-magenta-light-dark:#c2c2f6f0;
$text-blue-magenta:#9090CC;
$text-blue-magenta-dark:#6c6cccf0;

$text-slateblue-light:#EFF4FF ;
$text-slateblue-light-dark: #6F88AB;

$text-danger-light:#cb04040e;
$text-danger-light-dark:#ffd2ce;
$text-danger:#E92D2C;
$text-danger-dark:#b01a19;

$text-pink-light:#ffd4eb52;
$text-pink-light-dark:#fdd8f2;
$text-pink-light-extra-dark:#f5bfff;
$text-pink: #ff0086;
$text-pink-dark:#b50362;

$text-violet-light: #F8F2FF ; 
$text-violet-light-dark:#dbc0ff;
$text-violet:#AE7EDE;
$text-violet-dark:#7D44B5;

$text-magenta-extralight:#FFF3F9;
$text-magenta-light:#FFEEF7;
$text-magenta-light-dark:#ffddf5;
$text-magenta:#96006B;
$text-magenta-dark:#8E0065;

$text-warning-extralight:#fffbf2;
$text-warning-light:#FFF6EE;
$text-warning-light-dark:#F9CDA4;
$text-warning-dark:#FF7B00;
$text-warning:#FDDB5D;

$bg-white:#ffffff;
$text-white: #ffffff;
$text-gray:#ddd;
$bg-gray:#F8F8F8;
$bg-gray-extralight: #F7F8FC;
$bg-gray-light:#f9f9f9;
$border-gray:#F0F0F0;
$text-dark-gray:#6c757d;
$text-black: #212832;
$text-dark-black:#000;
$bg-black:#000;


//feature color
$clr-1: #FFEEDE;
$clr-2: #FFEFFA;
$clr-2: #FFEDFA;
$clr-3: #FEEBE9;
$clr-4: #EFF4FF;
$clr-5: #F4ECFF;
$clr-6: #FBE6FF;

$clr-7:#A4F1FC;
$clr-8:#9FF8A8;
$clr-9:#E48ECC;
$clr-10:#FFC48D;
$clr-11:#FFB5B5;
$clr-12:#8992C7;
$clr-13:#609AE8;
