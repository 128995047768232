// .loader{
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     align-items: center !important;
//     width: 100%;
//     height: 100vh;
//     background-color: #96006B;
//     color: #fff;
// }

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    // background-color: #96006B;
    .spinner-border,
    .spinner-grow {
      width: 3rem;
      height: 3rem;
      &.sm {
        width: 1.5rem;
        height: 1.5rem;
      }
      &.lg {
        width: 5rem;
        height: 5rem;
      }
    }
    .loader-message {
      margin-top: 1rem;
      font-size: 1.25rem;
      color: #fff; // Adjust this based on your design
    }
  }