
.clients-secton{
  width: 100%;
  height: auto;
  padding: 2rem 0rem;
  p{
    font-size: 1.125rem;
  }
}
.carousel-control-prev, 
.carousel-control-next{
  display: none;
}
.multiple_conent{
  &.nav-tabs{
    .tabs-left .mb-2-xs{
       display: none;
    }
  } 
}
.effortless-user {
    width: 100%;
    padding:3rem 0;
    margin: 0;
    &.box {
      .card{
        // box-shadow: 3px 3px 18px #0000000D;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.0509803922);
      }
    }
}

.accountable{
  .d-flex {
    margin-bottom: 1rem;
      &.row-reverse{
        flex-direction: row-reverse;
        justify-content: left;
      }  
      & >.flex-shrink-1{
        position: relative;
      }
      & >.flex-shrink-0{
        position: relative;
        z-index: 2;
      }
      .secure_icon {
        margin-right: 1.125rem;
        padding: 0.5rem;
        border-radius: 0.5rem;
        img{
          width: 2.5rem;
          height: 2.5rem;
        }
      }
      .subheading{
          font-size: 1.5rem;
          margin-top: 0rem;
          margin-bottom: 1rem;
      }
      .desc{
        font-size: 1rem;
      }
  }
}

@media (min-width: 576px){

}
@media (min-width: 768px){

}
@media (min-width: 992px){

  .effortless-user{
    .card{
      .card-body{
        min-height: 22.5rem;
      }
    }
  }
  .accountable{
    .d-flex{
      margin-bottom: 0rem;
      &.row-reverse{
        flex-direction: row;
        justify-content: right;
        & >.flex-shrink-0{
          margin-left: 1.5rem;
        }
      }
      .flex-shrink-1{
        &.text-right{
          position: relative;
          text-align: right;
          padding-right: 2rem;
            &:before{
              position: absolute;
              content:"";
              right: 1rem;
              width: 0.125rem;
              height: 3rem;
            }
        }
        &.text-left{
          text-align: left;
          padding-left: 2rem;
            &:before{
              position: absolute;
              content:"";
              left: 1rem;
              width: 0.125rem;
              height: 3rem;
            }
        }
        &:nth-child(odd){
          .line:before {
            content: "";
            position: absolute;
            z-index: 1;
            width: 1px;
            bottom: 0;
            top: 0px;
            right: -130px;
            height: 230px;
            transform: rotate(315deg);
          }
        }
        &:nth-child(even){
          .line:after  {
            content: "";
            position: absolute;
            z-index: 1;
            width: 1px;
            bottom: 0;
            top: -5px;
            left: -130px;
            height: 230px;
            transform: rotate(220deg);
          }
        }
      }
      .secure_icon {
        text-align: center;
        border-radius: 100%;
        width: 6rem;
        height: 6rem;
        padding: 1.125rem;
      }
    }
  }

}

  @media (min-width:1200px){

    .accountable{
      .d-flex{
        .flex-shrink-1{
          &:nth-child(odd){
            .line:before {
              top: 0px;
              right: -145px;
              height: 215px;
              transform: rotate(296deg);
            }
          }
          &:nth-child(even){
            .line:after  {
              top: -15px;
              left: -135px;
              height: 215px;
              transform: rotate(235deg);
  
            }
          }
        }
      }
    }


  }
  @media (min-width:1400px){

    .effortless-user{
        .card{
          .card-body{
            padding: 2rem;
            min-height: 23.5rem;
          }
        }
    } 

  }
  @media (min-width:1900px){

    .effortless-user{
      .rows{
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        .col-lg-4{
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }
      .card{
        .card-body{
          min-height: 21.5rem;
        }
      }
    } 
    
  }
  @media (max-width:991px){

      .accountable{
        .d-flex{
          .secure_icon {
            border-bottom: 1px solid #ddd;
            box-shadow: 0 2px 12px 0 #e4e4e4;
          }
        }
      }
    

  }
  @media (max-width:767px) {

  }
  @media (max-width: 575px) {

  }



